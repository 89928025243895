import {
    AllergySerializer,
    BedSerializer,
    ChronicDiagnosisSerializer,
    DemographicsSerializer,
    DemographicsSimpleSerializer,
    FacilitySimpleDetailSerializer,
    InfectionEntryViewSetPatientSerializer,
    LastVisitTaskSerializer,
    LocationSimpleSerializer,
    PatientAdditionalNameSerializer,
    PatientCensusSerializer,
    PatientDetailSerializer,
    PatientDetailsSerializer,
    PatientListSerializer,
    PatientNameWithFacilitySerializer,
    PatientOversightSerializer,
    PatientPhysicianTeamDetailedSerializer,
    PatientSerializer,
    PatientSimpleSerializer,
    PatientWithFacilitySerializer,
    PayerHistorySerializer,
    PersonWithPatientsSerializer,
    PhysicianSerializer,
} from '../@core/api.service';
import {Human} from './human';
import {CensusEvent} from './census-event';
import {TreatmentEntry, compareInfectionInformationFn, InfectionInformation} from './models';
import {User} from './user';
import {FallRisk} from './fall-risk';
import {Payer} from './payer';
import {CONSTANTS, getConst} from '../@core/constants';
import {assignOrCreate, ModelBase} from './model-base';
import {getId, Modify} from '../utils/type.utils';
import {Facility} from './facility';
import {toDateTime} from '../utils/date.utils';
import {DateTime} from 'luxon';
import {AccessorUtils, GetterCache} from '../utils/accessor.utils';

interface AdvancedDirective {
    code: string;
    description: string;
}

export type PatientPayerCategory = 'PAYER_CATEGORY_LONG_TERM' | 'PAYER_CATEGORY_SKILLED' | 'PAYER_CATEGORY_MEDICAID_PENDING' | 'PAYER_CATEGORY_HOSPICE';

export type LevelOfCare = 'Skilled' | 'LTC' | 'Hospice';

export const LEVEL_OF_CARE_OPTIONS = ['Skilled', 'LTC', 'Hospice'];

export class Patient extends Human implements PatientSerializer, PatientDetailSerializer, PatientListSerializer, PatientListSerializer, PatientSimpleSerializer, Modify<PatientOversightSerializer, {physician: User}> {
    id: number;
    origin: string;
    // customer is CustomerOfficeContactSerializer in case of PatientDetailSerializer
    // @ts-ignore
    customer: number;
    // @ts-ignore
    person: number;
    personDetails: PersonWithPatientsSerializer;
    physician_teams: number[];
    mrid: string;
    census_history: CensusEvent[];
    date_of_decease: Date | string;
    reason_of_decease: string;
    comment: string;
    // @ts-ignore
    facility: FacilitySimpleDetailSerializer;
    organization: number;
    allergies: AllergySerializer[];
    active_infections: InfectionInformation[];
    // @ts-ignore
    antibiotics: TreatmentEntry[];
    recurring_infections: InfectionInformation[];
    // #3168 TODO: check physician
    // @ts-ignore
    physician: number | PhysicianSerializer;
    chronic_diagnoses: ChronicDiagnosisSerializer[];
    details: PatientDetailsSerializer;
    fallRisk?: FallRisk;
    bed_name: string;
    location_name: string;
    last_payer: any;
    last_status: any;
    level_of_care: LevelOfCare;
    returned_from_hospital: boolean;
    hospital_id: number;
    current_treatments: any;
    status_detail: string;
    admission_date: Date;
    last_status_timestamp: Date;
    payers: PayerHistorySerializer[];
    is_patient_assigned_to_physician_team: boolean;
    is_followed: boolean;
    user_is_md: boolean;
    vaccines: any; // TODO later on string array
    bmi: number;
    height: number;
    weight: number;
    immunizations: any;
    advanced_directive: AdvancedDirective;
    no_known_allergies: Date | string;
    no_known_medications: Date | string;
    no_known_problems: Date | string;
    patient_additional_names: Array<PatientAdditionalNameSerializer>;
    // PatientListSerializer
    census_admission_date: Date | string;
    census_payer: string;
    census_status: string;
    census_date: Date | string;
    active_census_date: Date | string;
    census_bed: BedSerializer;
    mco_company?: string;
    last_task_by_type?: LastVisitTaskSerializer;
    last_visit_task?: LastVisitTaskSerializer;
    last_visit_task_by_filters?: LastVisitTaskSerializer;
    last_visit_task_by_physician?: LastVisitTaskSerializer;
    last_visit_task_routine?: LastVisitTaskSerializer;
    last_visit_task_routine_by_physician?: LastVisitTaskSerializer;
    last_visit_task_skilled?: LastVisitTaskSerializer;
    last_visit_task_skilled_by_physician?: LastVisitTaskSerializer;
    // PatientOversightSerializer
    census_location: LocationSimpleSerializer;
    // PatientDetailSerializer
    patient_physician_teams: Array<PatientPhysicianTeamDetailedSerializer>;
    demographics: DemographicsSerializer;
    user_as_patient: any;
    advanced_directives: any;
    current_living_situation: any;
    marital_status: any;
    primary_caregiver: any;
    smoking_status: any;
    tobacco_use: any;
    complexity_statement: string;

    constructor(patient?: PatientSerializer | PatientListSerializer | PatientDetailSerializer | PatientOversightSerializer | InfectionEntryViewSetPatientSerializer | Patient) {
        super(patient);
        if (patient) Object.assign(this, patient);
        if (this.census_history) {
            this.census_history = this.census_history.map(e => new CensusEvent(e));
            this.census_history.sort(function (a, b) {
                return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            });
        }
        if (this.facility && typeof this.facility === 'number') this.facility = Facility.get(this.facility);
        if (this.active_infections) this.active_infections = this.active_infections.map(x => new InfectionInformation(x));
        if (this.recurring_infections) this.recurring_infections = this.recurring_infections.map(x => new InfectionInformation(x));
        if (this.antibiotics) this.antibiotics = this.antibiotics.map(x => assignOrCreate(TreatmentEntry, x));
        // #3168 TODO: check physician
        // if (this.physician && typeof this.physician !== 'number') this.physician = new User(this.physician);
        if (this.last_visit_task?.user) this.last_visit_task.user = new User(this.last_visit_task.user);
        if (this.last_visit_task_by_filters?.user) this.last_visit_task_by_filters.user = new User(this.last_visit_task_by_filters.user);
        if (this.last_visit_task_by_physician?.user) this.last_visit_task_by_physician.user = new User(this.last_visit_task_by_physician.user);
        if (this.last_visit_task_routine) this.last_visit_task_routine.user = new User(this.last_visit_task_routine.user);
        if (this.last_visit_task_routine_by_physician) this.last_visit_task_routine_by_physician.user = new User(this.last_visit_task_routine_by_physician.user);
        if (this.last_visit_task_skilled) this.last_visit_task_skilled.user = new User(this.last_visit_task_skilled.user);
        if (this.last_visit_task_skilled_by_physician) this.last_visit_task_skilled_by_physician.user = new User(this.last_visit_task_skilled_by_physician.user);
        if (this.details) {
            if (this.details.fall_risk && typeof this.details.fall_risk === 'number') this.fallRisk = new FallRisk(this.details.fall_risk);
        }
        if (this.admission_date) this.admission_date = new Date(this.admission_date);
        if (this.last_status_timestamp) this.last_status_timestamp = new Date(this.last_status_timestamp);
        if (!this.allergies && (this as any)._allergies) this.allergies = (this as any)._allergies;
        Patient.updatePerson(this);
    }

    static getBstRecordId(patient) {
        return Patient.isBst(patient) ? `BST-${patient.id}` : null;
    }

    static updatePerson(patient: Patient) {
        if ((patient?.person as any)?.patients) {
            patient.personDetails = patient.person as any;
            patient.person = getId(patient.person);
        }
    }

    @GetterCache()
    get bstRecordId() {
        return Patient.getBstRecordId(this);
    }

    get advancedDirective(): string {
        const code = this.advanced_directive?.code;
        return code ? CONSTANTS[code] : '';
    }

    @GetterCache()
    get censusInformation() {
        const censusHistory = this.census_history?.filter(x => !x.facility || !this.facility || getId(x.facility) === getId(this.facility));
        if (!censusHistory?.length) return undefined;
        const censusStatus = {...censusHistory.reduce((a, b) => new Date(a.timestamp) > new Date(b.timestamp) ? a : b)};
        if (censusStatus.status === 'CENSUS_INFORMATION_CHANGE') censusStatus.status = this.censusStatus;
        return censusStatus;
    }

    @GetterCache()
    get lastAdmissionEvent() {
        if (!this.census_history || !this.census_history.length) return undefined;
        const admissionEvents = this.census_history.filter(x => x.status !== 'CENSUS_INFORMATION_CHANGE');
        if (!admissionEvents || !admissionEvents.length) return undefined;
        const event = admissionEvents.reduce(function (a, b) {
            const ad = new Date(a.timestamp);
            const bd = new Date(b.timestamp);
            return ad > bd ? a : b;
        });
        return event;
    }

    @GetterCache()
    get lastAdmission() {
        if (!this.census_history || !this.census_history.length) return undefined;
        const admissionEvents = this.census_history.filter(x => x.status === 'CENSUS_ADMISSION');
        if (!admissionEvents || !admissionEvents.length) return undefined;
        const event = admissionEvents.reduce(function (a, b) {
            const ad = new Date(a.timestamp);
            const bd = new Date(b.timestamp);
            return ad > bd ? a : b;
        });
        return event;
    }

    @GetterCache()
    get lastAdmissionDate(): Date {
        return this.admission_date || (this.lastAdmission ? this.lastAdmission.timestamp : undefined);
    }

    private _lastAttendance;

    @GetterCache()
    get lastAttendance() {
        if (!this._lastAttendance) {
            const ai = this.census_history ? this.census_history.findIndex(x => x.status === 'CENSUS_ADMISSION') : -1;
            if (ai < 0) return null;
            const events = this.census_history.slice(0, ai + 1);
            const li = events.findIndex(x => x.occupancyStatus === 'OUT_TEMP');
            const lastLeave = li > -1 ?
                {
                    leave: events[li],
                    comeback: events.slice(0, li).reverse().find(x => x.occupancyStatus === 'IN'),
                } :
                null;
            const d = events[0].occupancyStatus === 'OUT' ? events[0] : null;
            events.reverse();
            this._lastAttendance = {
                censusEvents: events,
                admission: events[0],
                lastLeave,
                discharge: d || null,
                daysSinceAdmission: Math.floor(((d ? new Date(d.timestamp) : new Date()).getTime() - new Date(events[0].timestamp).getTime()) / (1000 * 60 * 60 * 24)),
                daysSinceReturn: Math.floor(((d ? new Date(d.timestamp) : new Date()).getTime() - new Date(lastLeave?.comeback?.timestamp || events[0].timestamp).getTime()) / (1000 * 60 * 60 * 24)),
            };
        }
        return this._lastAttendance;
    }

    @GetterCache()
    get hospitalLeaves() {
        return this.census_history.filter(x => x.status === 'CENSUS_HOSPITAL_LEAVE');
    }

    @GetterCache()
    get censusStatus(): string {
        return this.last_status || this.census_status || (this.lastAdmissionEvent ? this.lastAdmissionEvent.status : undefined);
    }

    get lastAdmissionStatus() {
        return this.censusStatus;
    }

    get lastAdmissionStatusDetail(): string {
        return this.status_detail || (this.lastAdmissionEvent ? this.lastAdmissionEvent.status_detail : undefined);
    }

    @GetterCache()
    get censusStatusDate(): string | Date {
        return this.last_status_timestamp || this.census_date || (this.lastAdmissionEvent ? this.lastAdmissionEvent.timestamp : undefined);
    }

    get lastAdmissionStatusDate() {
        return this.censusStatusDate;
    }

    @GetterCache()
    get occupancyStatus() {
        return CensusEvent.occupancyStatus(this.censusStatus);
    }

    get daysSinceAdmission() {
        return Math.floor(((['IN', 'OUT_TEMP', 'OUTPATIENT'].includes(this.occupancyStatus) ? new Date().getTime() : new Date().getTime()) - new Date(this.lastAdmission.timestamp).getTime()) / (1000 * 60 * 60 * 24));
    }

    get hasStatusWarning() {
        return CensusEvent.hasStatusWarning(this.occupancyStatus);
    }

    @GetterCache()
    get censusColorClass() {
        return CensusEvent.colorClass(this.occupancyStatus);
    }

    @GetterCache()
    get censusStatusText() {
        return CensusEvent.statusText(this.censusStatus);
    }

    @GetterCache()
    get age(): number {
        const date = this.isDeceased ? toDateTime(this.deceasedDateTime) : DateTime.now();
        return this.date_ob ? Math.trunc(date.diff(toDateTime(this.date_ob), 'years').years) : undefined;
    }

    set age(x) {
        AccessorUtils.getCache(this).age = x;
    }

    @GetterCache()
    get ageText(): string {
        return this.isDeceased ? '†' : `${this.age} years`;
    }

    private _payer: string;

    static getPayer(patient): string {
        return (!(patient instanceof Patient) && patient.payer) || patient._payer || patient.last_payer || patient.census_payer || patient.censusStatus?.payer;
    }

    static getStatus(patient) {
        return getConst(patient.last_status);
    }

    @GetterCache()
    get payer(): string {
        return Patient.getPayer(this);
    }

    set payer(payer: string) {
        this._payer = payer;
        AccessorUtils.clearCacheKey(this, 'payer');
        AccessorUtils.clearCacheKey(this, 'activePayers');
    }

    @GetterCache()
    get activePayers() {
        return this.payers.filter(x => !x.end_date);
    }

    @GetterCache()
    get location() {
        return this.censusInformation && ['IN', 'OUT_TEMP', 'OUTPATIENT'].includes(this.occupancyStatus) ? this.censusInformation.location : null;
    }

    static getLocationName(patient): string {
        return patient.location_name || patient.census_location?.name || patient.location?.name || patient.census_bed?.location?.name || undefined;
    }

    @GetterCache()
    get locationName(): string {
        return Patient.getLocationName(this);
    }

    @GetterCache()
    get bed() {
        return this.censusInformation && ['IN', 'OUT_TEMP', 'OUTPATIENT'].includes(this.occupancyStatus) ? this.censusInformation.bed : null;
    }

    static getBedName(patient): string {
        return patient.bed_name || patient.census_bed?.name || patient.bed?.name || undefined;
    }

    @GetterCache()
    get bedName(): string {
        return Patient.getBedName(this);
    }

    static getLocationBed(patient): string {
        const locationName = Patient.getLocationName(patient);
        return locationName ? `${locationName}/${Patient.getBedName(patient) || ''}` : '';
    }

    @GetterCache()
    get locationBed(): string {
        return Patient.getLocationBed(this);
    }

    get isMedicareDangered(): boolean {
        return this.payer === 'Medicare A';
    }

    static isDeceased(patient): boolean {
        return !!patient.date_of_decease;
    }

    @GetterCache()
    get isDeceased(): boolean {
        return Patient.isDeceased(this);
    }

    @GetterCache()
    get deceasedDateTime(): Date {
        return this.date_of_decease ? new Date(this.date_of_decease) : undefined;
    }

    @GetterCache()
    get recurringInfectionsGrouped() {
        if (!this.recurring_infections || !this.recurring_infections.length) return null;
        return this.recurring_infections.sort(compareInfectionInformationFn).reduce((acc, curr) => {
            const x = acc.find(x => x.icd.id === curr.icd.id);
            if (x) x.occurrences.push(curr);
            else acc.push({icd: curr.icd, occurrences: [curr]});
            return acc;
        }, []);
    }

    @GetterCache()
    get antibioticsGrouped() {
        if (!this.antibiotics || !this.antibiotics.length) return null;
        return this.antibiotics.reduce((acc, curr) => {
            if (!curr.isConsolidatedRecord) return acc;
            const x = acc.find(x => x.drug_name.id === curr.drug_name.id);
            if (x) x.occurrences.push(curr);
            else acc.push({drug_name: curr.drug_name, drug: curr.drug, occurrences: [curr]});
            return acc;
        }, []);
    }

    static getPayerCategory(patient: PatientCensusSerializer): PatientPayerCategory {
        if (patient) {
            if (Payer.isMedicaidPending(Patient.getPayer(patient))) return 'PAYER_CATEGORY_MEDICAID_PENDING';
            if (Patient.isLTC(patient)) return 'PAYER_CATEGORY_LONG_TERM';
            if (Patient.isHospice(patient)) return 'PAYER_CATEGORY_HOSPICE';
            if (Patient.isSkilled(patient)) return 'PAYER_CATEGORY_SKILLED';
        }
        return undefined;
    }

    static isMoqi(patient: any, daysInFacility: number): boolean {
        return Patient.getPayerCategory(patient) === 'PAYER_CATEGORY_LONG_TERM' && daysInFacility >= 100;
    }

    static isMedicaid(patient): boolean {
        return Payer.isMedicaid(Patient.getPayer(patient));
    }

    get isMedicaid(): boolean {
        return Patient.isMedicaid(this);
    }

    static isPrivate(patient): boolean {
        return Payer.isPrivate(Patient.getPayer(patient));
    }

    get isPrivate(): boolean {
        return Patient.isPrivate(this);
    }

    static isHospice(patient: Patient | PatientCensusSerializer): boolean {
        if (patient.level_of_care) return patient.level_of_care === 'Hospice';
        return Payer.isHospice(Patient.getPayer(patient));
    }

    get isHospice(): boolean {
        return Patient.isHospice(this);
    }

    static isLTC(patient: Patient | PatientCensusSerializer): boolean {
        if (patient.level_of_care) return patient.level_of_care === 'LTC';
        return Payer.isLTC(Patient.getPayer(patient));
    }

    get isLTC(): boolean {
        return Patient.isLTC(this);
    }

    static isLongTermResident(patient): boolean {
        return Payer.isLongTermResident(Patient.getPayer(patient));
    }

    get isLongTermResident(): boolean {
        return Patient.isLongTermResident(this);
    }

    static isSkilled(patient: Patient | PatientCensusSerializer): boolean {
        if (patient.level_of_care) return patient.level_of_care === 'Skilled';
        return Payer.isSkilled(Patient.getPayer(patient));
    }

    @GetterCache()
    get isSkilled(): boolean {
        return Patient.isSkilled(this);
    }

    static payerPending(patient): boolean {
        return Payer.isPending(Patient.getPayer(patient));
    }

    get payerPending(): boolean {
        return Patient.payerPending(this);
    }

    static isCcmEligible(patient): boolean {
        return patient.details ? patient.details.calculated_ccm_eligible : undefined;
    }

    @GetterCache()
    get isCcmEligible(): boolean {
        return Patient.isCcmEligible(this);
    }

    static isCcmConsented(patient): boolean {
        return patient.details ? patient.details.ccm_consent_received : undefined;
    }

    @GetterCache()
    get isCcmConsented(): boolean {
        return Patient.isCcmConsented(this);
    }

    static visitTypeText(visitTypeKey: string): string {
        return getConst(visitTypeKey);
    }

    get lastVisitTypeText(): string {
        return Patient.visitTypeText(this.last_visit_task.type);
    }

    static searchText(patient): string {
        return [Patient.getName(patient), Patient.getName(patient, 'firstNameFirst'), patient.mrid, patient.date_ob, Patient.getLocationBed(patient)].filter(x => !!x).join(' ');
    }

    @GetterCache()
    get searchText() {
        return Patient.searchText(this);
    }

    static isBst(patient) {
        return patient.origin === 'BST' || !!patient.customer;
    }

    get isBst() {
        return Patient.isBst(this);
    }
}

export class RelatedPatient extends ModelBase implements PatientSerializer, PatientWithFacilitySerializer, PatientNameWithFacilitySerializer {
    id: number;
    origin: string;
    customer: number;
    person: number;
    physician_teams: number[];
    mrid: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    title_name: string;
    date_ob: Date | string;
    date_of_decease: Date | string;
    gender: string;
    comment: string;
    // TODO: this can be FacilitySimpleSerializer
    // @ts-ignore
    facility: number;
    organization: number;
    // #3168 TODO: check physician
    physician: number;
    census_location: LocationSimpleSerializer;
    census_bed: BedSerializer;
    census_status: string;
    census_date: Date | string;
    active_census_date: Date | string;
    census_admission_date: Date | string;
    census_payer: string;
    level_of_care: any;
    demographics: DemographicsSimpleSerializer;
    reason_of_decease: string;

    assign(x: PatientSerializer | PatientWithFacilitySerializer | RelatedPatient) {
        const {facility, ...rest} = x;
        super.assign(rest);
        if (facility && (!this.facility || getId(facility) !== getId(this.facility)) || typeof this.facility === 'number') {
            this.facility = facility as any;
        }
    }

    get name(): string {
        return Human.getName(this);
    }

    get isBst() {
        return Patient.isBst(this);
    }

    @GetterCache()
    get bstRecordId() {
        return Patient.getBstRecordId(this);
    }

    @GetterCache()
    get occupancyColorClass() {
        return CensusEvent.colorClass(CensusEvent.occupancyStatus(this.census_status));
    }
}
