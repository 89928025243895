import {assignOrCreate, ModelBase} from './model-base';
import {map, shareReplay} from 'rxjs/operators';
import {
    APIService,
    SnomedConceptSearchViewSetQueryParams,
    SnomedConceptSerializer,
    SnomedCTConceptSearchResultSerializer,
} from '../@core/api.service';
import {MethodCache} from '../utils/accessor.utils';
import {Observable, of} from 'rxjs';
import {SNOMED_VALUESET_OIDS} from '../@core/snomed_valueset_oids';

export type SnomedVsacCategory = keyof typeof SNOMED_VALUESET_OIDS;

export type SnomedCategory = 'imaging' | 'procedure' | 'measurement' | 'functional_status' | 'familial_relationship' | 'general_relationship';

export interface SnomedFilters extends SnomedConceptSearchViewSetQueryParams {
    snomed_category?: SnomedCategory;
    vsac_category?: SnomedVsacCategory | SnomedVsacCategory[];
}

export type SnomedConceptSearchResult = SnomedConcept & SnomedCTConceptSearchResultSerializer;

export class SnomedConcept extends ModelBase implements SnomedConceptSerializer {
    id: number;
    term: string;
    name: string;
    code: number;

    static clearCacheOnUserChange = false;

    assign(x: any, ...args) {
        super.assign(x, ...args);
        this.name = this.term;
        this.code = this.id;
    }

    @MethodCache('convertObject')
    static list(filtersOrCategory: SnomedFilters | SnomedCategory | SnomedVsacCategory): Observable<SnomedConceptSearchResult[]> {
        if (typeof filtersOrCategory === 'string') return this.list(convertToSnomedFilters(filtersOrCategory));

        return APIService.SnomedConceptSearchViewSet.search(filtersOrCategory).pipe(
            map(res => res.map(result => {
                const {id, term} = result;
                const sc: SnomedConcept = this.get(id) || assignOrCreate(SnomedConcept, {id, term});
                return filtersOrCategory.search ? {...sc, ...result} as SnomedConceptSearchResult : sc;
            })),
            shareReplay(1),
        );
    }

    static search(filters: SnomedFilters) {
        return this.list(filters);
    }

    static retrieve(id: number): Observable<SnomedConcept> {
        const sc = this.get(id);
        return sc ?
            of(sc) :
            APIService.SnomedConceptSearchViewSet.retrieve(id).pipe(map(x =>
                assignOrCreate(SnomedConcept, {id, term: x.term})
            ));
    }

    static getViewSet(categoryOrBaseFilters: SnomedCategory | SnomedVsacCategory | SnomedVsacCategory[] | Omit<SnomedFilters, 'search'>) {
        const baseFilters = convertToSnomedFilters(categoryOrBaseFilters);
        return {
            list: (filters: SnomedFilters) => SnomedConcept.search({...baseFilters, ...filters}),
            retrieve: (id: number) => SnomedConcept.retrieve(id),
        };
    }
}

const convertToSnomedFilters = (categoryOrFilters: SnomedCategory | SnomedVsacCategory | SnomedVsacCategory[] | SnomedFilters) => {
    if (Array.isArray(categoryOrFilters)) return {vsac_category: categoryOrFilters};
    if (typeof categoryOrFilters === 'string') {
        return categoryOrFilters in SNOMED_VALUESET_OIDS ?
            {vsac_category: categoryOrFilters as SnomedVsacCategory} :
            {snomed_category: categoryOrFilters as SnomedCategory};
    }
    return categoryOrFilters;
};
