import {ModelBase, ModelBaseAccessor} from './model-base';
import {ActionSimpleReadSerializer, CCDAAuthorImportSerializer, DiagnosisDetailedSerializer} from '../@core/api.service';
import {ICDCode} from './ICD-code';
import {getId} from '../utils/type.utils';
import {SnomedConcept} from './snomed-concept';
import {DISEASE_STATUSES} from '../@core/disease_statuses';

export class Diagnosis extends ModelBase implements DiagnosisDetailedSerializer {
    id: number;
    patient: number;
    @ModelBaseAccessor(ICDCode) icd: ICDCode;
    @ModelBaseAccessor(SnomedConcept) snomed_concept: SnomedConcept;
    category: string;
    date_diagnosed: Date | string;
    date_resolved: Date | string;
    related_treatments: any;
    is_deleted: boolean;
    patient_document: number;
    modified_at: Date | string;
    copied_from: number;
    associated_health_concern: number;
    is_health_concern: boolean;
    last_comment_action: ActionSimpleReadSerializer;
    // CCD Reconciliation
    author?: CCDAAuthorImportSerializer[];

    get icdCode() {
        return this.icd?.code || '';
    }

    get diagnosis() {
        return this.icd?.description || this.snomed_concept?.term || '';
    }

    get is_chronic() {
        return this.icd?.is_chronic;
    }

    get isActive() {
        return !this.is_deleted && !this.date_resolved;
    }

    get diseaseStatus() {
        const key = this.last_comment_action?.disease_status;
        return key && DISEASE_STATUSES[key];
    }

    getSerialized(obj: any = this): any {
        const {icd, snomed_concept, ...rest} = obj;
        return {...super.getSerialized(rest), icd: getId(icd), snomed_concept: getId(snomed_concept)};
    }
}
