import {
    NewPasswordRequestSerializer,
    PhysicianSerializer,
    SMARTUserInfoSerializer,
    UserAuthSerializer,
    UserDetailedSerializer,
    UserSerializer,
    UserSettingsSerializer,
    UserSimpleSerializer,
} from '../@core/api.service';
import {Human} from './human';
import {getConst} from '../@core/constants';
import {Group} from './group';
import {Facility} from './facility';
import {PhysicianTeamUser} from './physician-team';
import {assignOrCreate} from './model-base';
import {Organization} from './organization';

export class User extends Human implements UserAuthSerializer, UserSerializer, UserDetailedSerializer, PhysicianSerializer {
    id: number;
    customer: any;
    organization: any;
    email: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    title_name: string;
    organization_group: any;
    admin_departments: number[];
    admin_organization_groups: number[];
    badges: any[];
    roles: any;
    facilities: any[];
    departments: number[];
    organizations: Organization[] = [];
    permissions: any[];
    password_requests: NewPasswordRequestSerializer[] = [];
    settings: UserSettingsSerializer;
    is_active: boolean;
    is_suspended: boolean;
    created_at: Date | string;
    timezone: string;
    comment: string;
    phone: string;
    origin: string;
    ip_filter: string;
    npi_number: string;
    has_npi: boolean;
    active_patients: any;
    job_title: string;
    allowed_domain: string;
    last_signin: Date | string;
    groups: number[];
    lead_physicians: UserSimpleSerializer[];
    billing_npi_number: string;
    is_np: boolean;
    number_of_logins: number;
    smart_user_info: SMARTUserInfoSerializer;
    first_signin?: Date | string;
    employee_id: string;
    _permissions?: any;

    constructor(user?: UserSerializer | UserAuthSerializer | UserDetailedSerializer | PhysicianSerializer | PhysicianTeamUser) {
        super(user);
        if (user) Object.assign(this, user);

        if (this.organizations) this.organizations = this.organizations.map(x => assignOrCreate(Organization, x));
        if (this.roles) this.roles.sort((a, b) => getConst(a).localeCompare(getConst(b)));
        if (this.groups) this.groups.sort((a, b) => Group.get(a)?.name?.localeCompare(Group.get(b)?.name));
        if (this.facilities) this.facilities.sort((a, b) => Facility.get(a)?.name?.localeCompare(Facility.get(b)?.name));
        if (this.lead_physicians) this.lead_physicians.sort((a, b) => Human.getName(a, 'firstNameFirst').localeCompare(Human.getName(b, 'firstNameFirst')));
    }

    get isSuspended() {
        return this.is_suspended || this.permissions?.includes('HOMEPAGE_SUSPENDED');
    }

    get username(): string {
        return this.email;
    }

    get nameWithMail(): string {
        return `${this.first_name} ${this.last_name} (${this.email})`;
    }

    get isSuperAdmin(): boolean {
        return this.roles.indexOf('ROLE_SUPER_ADMIN') > -1;
    }
}
