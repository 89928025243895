export class FallRisk {
    value: number;
    assessment: string;
    colorClass: string;
    datesOfFalls: any; //TODO later on string array

    constructor(x: number) {
        Object.assign(this, FallRisk.evaluate(x));
    }

    static evaluate(x: number) {
        return {
            value: x,
            // assessment: 'High'
            assessment: x > 13 ? 'High' : x > 5 ? 'Moderate' : 'Low',
            colorClass: x > 13 ? 'danger' : x > 5 ? 'warning' : 'muted',
        };
    }
}
