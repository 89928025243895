import {ModelBase} from './model-base';
import {APIService, CachedPhysicianNameSimpleSerializer, FaxStatusSerializer} from '../@core/api.service';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {FAX_QUEUE_STATUSES} from '../@core/fax_queue_statuses';

export type FaxStatusKey = keyof typeof FAX_QUEUE_STATUSES;

export class FaxStatus extends ModelBase implements FaxStatusSerializer {
    id: number;
    created_at: Date | string;
    created_by: CachedPhysicianNameSimpleSerializer;
    error_code: string;
    fax_number: string;
    modified_at: Date | string;
    notification_sent: boolean;
    response_time: Date | string;
    status: FaxStatusKey;
    template: string;
    timestamp: Date | string;
    statusText: string;
    color: {class: string; opacity: number};
    label: string;
    tooltip: string;

    static viewSet = APIService.FaxStatusHistoryViewSet;

    assign(x: FaxStatusSerializer) {
        super.assign(x);

        switch (this.status) {
            case 'QUEUED':
            case 'ADDED_TO_SRFAX':
                this.color = {class: null, opacity: .33};
                break;
            case 'SENT':
                this.color = {class: 'success', opacity: 1};
                break;
            case 'CANCELLED':
            case 'ERROR':
                this.color = {class: 'danger', opacity: 1};
                break;
            default:
                this.color = {class: null, opacity: .33};
        }

        this.timestamp = this.modified_at || this.response_time || this.created_at;
        this.statusText = FAX_QUEUE_STATUSES[this.status] || this.status;
        const dateText = SimpleDatePipe.transform(this.timestamp, false, true);
        this.label = this.status ? `${this.statusText} - ${dateText}` : null;
        this.tooltip = [this.error_code, dateText].filter(x => x).join(' - ');
    }
}
