export function removeNewLinesFromEnd(x: string): string {
    if (!x) return x;
    while (x.endsWith('\n')) x = x.slice(0, -1);
    return x;
}

export function removeQuotes(x: string): string {
    if (!x) return x;
    return x.replace(`"`, '').replace(`'`, '');
}

export function toCamelCase(x: string): string {
    return x.replace(/-([a-z])/g, g => g[1].toUpperCase());
}

export function toKebabCase(x: string): string {
    return x.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase();
}

export function capitalize(x: string): string {
    if (!x) return x;
    return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
}

export function capitalizeAll(x: string): string {
    const separator = x.split('').find(l => [' ', '_', '-'].includes(l));
    return x.split(separator).map(w => capitalize(w)).join(' ');
}

export function toIcdFormat(x: string): string {
    if (!x || x === '0') return '';

    return x.length > 3 ? `${x.substring(0, 3)}.${x.substring(3)}` : x;
}

export function limitText(x: string, limit: number, hasEllipsis = true): string {
    if (limit && x && x.length > limit) {
        let t = x.slice(0, limit);
        const si = t.lastIndexOf(' ');
        if (si > -1) t = t.slice(0, si);
        if (t.endsWith(',')) t = t.slice(0, t.length - 1);
        return hasEllipsis ? `${t}\u2026` : t;
    }
    return x;
}

export function randomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
}

export function filterBasedOnSearchString<T>(arr: T[], getVal: (item: T) => string, query: string): T[] {
    const queryWords = query.toLowerCase().split(' ');
    const indexPos = arr.reduce((res, x, i) => {
        const keywords = getVal(x).toLowerCase().split(' ');
        let indexSum = 0;
        for (const q of queryWords) {
            const subIndexes = keywords.map(k => k.indexOf(q)).filter(i => i > -1);

            if (!subIndexes.length) return res;

            indexSum += Math.min(...subIndexes);
        }

        const resI = res.findIndex(x => x.score > indexSum);
        res.splice(resI, 0, {arrIndex: i, score: indexSum});

        return res;
    }, ([] as {arrIndex: number; score: number}[]));

    return indexPos.map(x => arr[x.arrIndex]);
}

/**
 * https://stackoverflow.com/a/3561711
 */
export function escapeForRegExp(x: string): string {
    return x.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
