import {PhysicianSerializer, TaskStatusHistorySerializer} from '../../../../@core/api.service';
import {GetterCache} from '../../../../utils/accessor.utils';
import {ModelBase} from '../../../../models/model-base';
import {Human} from '../../../../models/human';
import {SimpleDatePipe} from '../../../../@theme/pipes/simple-date.pipe';

export type TaskBillingStatus = 'BILLING_VERIFIED' | 'BILLING_VALIDATED' | 'BILLING_PENDING' | 'BILLING_UPDATED' | 'APPROVED_BY_PHYSICIAN';

export class TaskBillingStatusDetails extends ModelBase implements TaskStatusHistorySerializer {
    comment: string;
    fax: boolean;
    fax_error_code: string;
    fax_notification_sent: boolean;
    fax_response_timestamp: Date | string;
    fax_status: string;
    fax_template: string;
    id: number;
    is_sms_sent: boolean;
    result_number: number;
    sms: boolean;
    status: TaskBillingStatus;
    timestamp: Date | string;
    user: PhysicianSerializer;

    get isEditable() {
        return !['BILLING_VERIFIED', 'BILLING_VALIDATED', 'APPROVED_BY_PHYSICIAN'].includes(this.status);
    }

    static getBillingColorClass(status: string) {
        switch (status) {
            case 'BILLING_VERIFIED':
                return 'success';
            case 'BILLING_VALIDATED':
                return 'info';
            case 'BILLING_UPDATED':
                return 'primary';
            case 'BILLING_PENDING':
                return 'warning';
        }
        return null;
    }

    @GetterCache()
    get billingColorClass() {
        return TaskBillingStatusDetails.getBillingColorClass(this.status);
    }

    @GetterCache()
    get billingStatusTooltip() {
        let x = `${Human.getName(this.user)} @ ${SimpleDatePipe.transform(this.timestamp)}`;
        if (this.comment) x += ` - ${this.comment}`;
        return x;
    }
}
