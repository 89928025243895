import {Subject} from 'rxjs';
import {Id} from '../../utils/type.utils';

export class CheckPatientCache {
    static queue: {[key: string]: {[patientId: Id]: Id[]}} = {};
    static checkKey$ = new Subject<string>();

    static add(patientId: Id, key: string | string[], objectId: Id | Id[]) {
        (Array.isArray(key) ? key : [key]).forEach(k => {
            (Array.isArray(objectId) ? objectId : [objectId]).forEach(oid => {
                this._addToQueue(patientId, k, oid);
            });

            setTimeout(() => this.checkKey$.next(k));
        });
    }

    private static _addToQueue(patientId: Id, key: string, objectId: Id) {
        if (!this.queue[key]) this.queue[key] = {};
        if (!this.queue[key][patientId]) this.queue[key][patientId] = [];
        if (!this.queue[key][patientId].includes(objectId)) this.queue[key][patientId].push(objectId);
    }
}
