import {ModelBase} from './model-base';
import {APIService, RxNormCodeSerializer} from '../@core/api.service';

export class RxNormCode extends ModelBase implements RxNormCodeSerializer {
    id: number;
    term: string;

    static viewSet = APIService.RxNormViewSet;

    assign(x: RxNormCodeSerializer, ...args) {
        super.assign(x, ...args);
    }
}
