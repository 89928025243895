import {takeUntil} from 'rxjs/operators';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {FacilityChooserService, OrganizationFacility} from './facility-chooser.service';
import {Subject} from 'rxjs';

@Directive()
export abstract class UnsubscribeComponent implements OnDestroy {
    compOnDestroy?(): void;

    protected cancelRequest$ = new Subject<void>();
    protected destroy$ = new Subject<void>();

    ngOnDestroy() {
        this.cancelRequest$.next();
        this.cancelRequest$.complete();
        this.destroy$.next();
        this.destroy$.complete();
        if (this.compOnDestroy) this.compOnDestroy();
    }
}

@Directive()
export abstract class FcComponent extends UnsubscribeComponent implements OnInit {
    compOnInit?(): void;

    compOnFacChange?(orgFac: OrganizationFacility): void;

    constructor(public fc: FacilityChooserService) {
        super();
    }

    ngOnInit() {
        if (this.compOnInit) this.compOnInit();
        this.fc.pipe(takeUntil(this.destroy$)).subscribe(orgFac => {
            if (this.compOnFacChange) this.compOnFacChange(orgFac);
        });
    }
}
