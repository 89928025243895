import {BedSerializer, CensusHistorySerializer, HospitalSerializer, LocationSerializer} from '../@core/api.service';
import {getConst} from '../@core/constants';

type OccupancyStatus = 'IN' | 'OUT' | 'OUT_TEMP' | 'OUTPATIENT' | 'INFORMATION_CHANGE' | 'PENDING_ADMISSION';

export class CensusEvent implements CensusHistorySerializer {
    id: number;
    patient_id: any;
    location: LocationSerializer;
    status: string;
    status_detail: string;
    timestamp: Date;
    payer: string;
    hospital: HospitalSerializer;
    bed: BedSerializer;
    source_of_payment: number;
    facility: number;
    comment: string;

    constructor(event?: CensusHistorySerializer) {
        if (event) Object.assign(this, event);
    }

    static occupancyStatus(status): OccupancyStatus {
        switch (status) {
            case 'CENSUS_ADMISSION':
            case 'CENSUS_READMISSION':
            case 'CENSUS_RETURN':
                return 'IN';
            case 'CENSUS_DISCHARGE_RNA':
            case 'CENSUS_EXPIRED':
                return 'OUT';
            case 'CENSUS_DISCHARGE_RE':
            case 'CENSUS_HOSPITAL_LEAVE':
            // case 'CENSUS_STATUS_9':
            case 'CENSUS_THERAPEUTIC_LEAVE':
                return 'OUT_TEMP';
            case 'CENSUS_OUTPATIENT':
            case 'CENSUS_OUTPATIENT_DISCHARGE':
                return 'OUTPATIENT';
            case 'CENSUS_INFORMATION_CHANGE':
                return 'INFORMATION_CHANGE';
            case 'CENSUS_PENDING_ADMISSION':
                return 'PENDING_ADMISSION';
            default:
                return undefined;
        }
    }

    static statusText(status): string {
        return getConst(status);
    }

    static colorClass(occupancyStatus: OccupancyStatus) {
        switch (occupancyStatus) {
            case 'IN':
                return 'success';
            case 'OUT':
                return 'danger';
            case 'OUT_TEMP':
                return 'warning';
            case 'OUTPATIENT':
                return 'info';
            case 'INFORMATION_CHANGE':
                return 'neutral';
            case 'PENDING_ADMISSION':
                return 'secondary';
            default:
                return undefined;
        }
    }

    static hasStatusWarning(occupancyStatus: OccupancyStatus) {
        return ['OUT', 'OUT_TEMP', 'PENDING_ADMISSION'].includes(occupancyStatus);
    }

    get occupancyStatus() {
        return CensusEvent.occupancyStatus(this.status);
    }

    get statusText(): string {
        return CensusEvent.statusText(this.status);
    }

    get statusDetailText(): string {
        return getConst(this.status_detail);
    }

    get colorClass(): string {
        return CensusEvent.colorClass(this.occupancyStatus);
    }

    get occupancyColorClass(): string {
        const cc = this.colorClass;
        return cc == 'neutral' ? 'primary' : cc;
    }
}
