import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateInput, DateTime, DateTimeOptions} from 'luxon';
import {FacilityChooserService} from '../@core/facility-chooser.service';

export const DEF_HAS_TIME = false;

export interface NgbTimeStruct {
    hour?: number;
    minute?: number;
    second?: number;
}

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {
}

export interface NgbMonthStruct {
    year: number;
    month: number;
}

export type Interval = 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export const DAY_OF_WEEK_CHOICES = [
    {value: 'MON', label: 'Monday'},
    {value: 'TUE', label: 'Tuesday'},
    {value: 'WED', label: 'Wednesday'},
    {value: 'THU', label: 'Thursday'},
    {value: 'FRI', label: 'Friday'},
    {value: 'SAT', label: 'Saturday'},
    {value: 'SUN', label: 'Sunday'},
];

export function toDateTime(date: string | Date | number | DateTime, options?: DateTimeOptions): DateTime {
    if (typeof date === 'string') return DateTime.fromISO(date, options);
    if (date instanceof Date) return DateTime.fromJSDate(date, options);
    if (typeof date === 'number') return DateTime.fromMillis(date, options);
    if (date instanceof DateTime) return options?.zone ? date.setZone(options.zone) : date;
}

export function dateTimeToNgbDateStruct(dt: DateTime, hasTime: boolean = DEF_HAS_TIME, timezone?: string): NgbDateTimeStruct {
    if (timezone) dt = dt.setZone(timezone);
    const res = {year: dt.year, month: dt.month, day: dt.day};
    if (hasTime) {
        Object.assign(res, {
            hour: dt.hour,
            minute: dt.minute,
            second: dt.second,
        });
    }
    return res;
}

export function dateToNgbDateStruct(date: Date | string, hasTime: boolean = DEF_HAS_TIME, timezone?: string): NgbDateTimeStruct {
    return dateTimeToNgbDateStruct(toDateTime(date), hasTime, timezone);
}

export function ngbDateStructToDateTime(ngbDateTime: NgbDateTimeStruct, hasTime: boolean = DEF_HAS_TIME, timezone?: string): DateTime {
    let date: DateInput = {year: ngbDateTime.year, month: ngbDateTime.month, day: ngbDateTime.day};
    if (hasTime) date = {...date, hour: ngbDateTime.hour, minute: ngbDateTime.minute, second: ngbDateTime.second};
    return DateTime.fromObject({...date}, {zone: timezone});
}

export function ngbDateStructToDate(ngbDateTime: NgbDateTimeStruct, hasTime: boolean = DEF_HAS_TIME, timezone?: string): Date {
    return ngbDateStructToDateTime(ngbDateTime, hasTime, timezone).toJSDate();
}

export function getNgbDate(ngbDateTime: NgbDateTimeStruct): NgbDateStruct {
    return {year: ngbDateTime.year, month: ngbDateTime.month, day: ngbDateTime.day};
}

export function getNgbMonth(ngbDateTime: NgbDateTimeStruct): NgbMonthStruct {
    return {year: ngbDateTime.year, month: ngbDateTime.month};
}

export function getNgbTime(ngbDateTime: NgbDateTimeStruct, needSeconds = false): NgbTimeStruct {
    return {hour: ngbDateTime.hour, minute: ngbDateTime.minute, second: needSeconds ? ngbDateTime.second : 0};
}

export function toNgbDateStruct(date: Date | DateTime | NgbDateStruct | NgbDateTimeStruct | string, hasTime: boolean = DEF_HAS_TIME, timezone?: string): NgbDateTimeStruct {
    if (!date) return date as NgbDateTimeStruct;
    if (typeof date === 'string' || date instanceof Date) return dateToNgbDateStruct(date, hasTime, timezone);
    if (date instanceof DateTime) return dateTimeToNgbDateStruct(date, hasTime, timezone);
    if (date.hasOwnProperty('year') && date.hasOwnProperty('month') && date.hasOwnProperty('day')) return date;
    throw new Error('Invalid argument supplied to toNgbDateStruct!');
}

export function toNgbDateStructFrom(hasTime: boolean = DEF_HAS_TIME, timezone?: string) {
    return {
        date: (date: Date | string) => dateToNgbDateStruct(date, hasTime, timezone),
        text: (date: string) => dateToNgbDateStruct(date, hasTime, timezone),
        dateTime: (date: DateTime) => dateTimeToNgbDateStruct(date, hasTime),
        ngbDate: (date: NgbDateStruct | NgbDateTimeStruct) => date,
    };
}

export function fromNgbDateStructTo(hasTime: boolean = DEF_HAS_TIME, timezone?: string, hasSeconds = false) {
    return {
        date: (ngbDateTime: NgbDateTimeStruct) => ngbDateStructToDate(ngbDateTime, hasTime, timezone),
        dateTime: (ngbDateTime: NgbDateTimeStruct) => ngbDateStructToDateTime(ngbDateTime, hasTime, timezone),
        ngbDate: (ngbDateTime: NgbDateTimeStruct) => ngbDateTime,
        text: (ngbDateTime: NgbDateTimeStruct) => fromDateTimeToFormat(hasTime, timezone, hasSeconds).text(ngbDateStructToDateTime(ngbDateTime, hasTime, timezone)),
    };
}

export function fromDateTimeToFormat(hasTime: boolean = DEF_HAS_TIME, timezone?: string, hasSeconds = true) {
    return {
        date: (dt: DateTime) => dt.toJSDate(),
        dateTime: (dt: DateTime) => dt,
        ngbDate: (dt: DateTime) => dateTimeToNgbDateStruct(dt, hasTime, timezone),
        text: (dt: DateTime) => hasTime ? dt.toISO() : dt.toISODate(),
    };
}

export const dateTimeAddInterval = {
    HOUR: (d: DateTime, multiplier = 1) => d.plus({hours: multiplier}),
    DAY: (d: DateTime, multiplier = 1) => d.plus({days: multiplier}),
    WEEK: (d: DateTime, multiplier = 1) => d.plus({weeks: multiplier}),
    MONTH: (d: DateTime, multiplier = 1) => d.plus({months: multiplier}),
    YEAR: (d: DateTime, multiplier = 1) => d.plus({years: multiplier}),
};

export const dateTimeSubtractInterval = {
    HOUR: (d: DateTime, multiplier = 1) => d.minus({hours: multiplier}),
    DAY: (d: DateTime, multiplier = 1) => d.minus({days: multiplier}),
    WEEK: (d: DateTime, multiplier = 1) => d.minus({weeks: multiplier}),
    MONTH: (d: DateTime, multiplier = 1) => d.minus({months: multiplier}),
    YEAR: (d: DateTime, multiplier = 1) => d.minus({years: multiplier}),
};

export const dateTimeNextExpectedDate: {[key in Interval]: (date: DateTime, y?: number) => DateTime} = {
    HOUR: (d: DateTime, multiplier = 1) => d.plus({hours: multiplier}),
    DAY: (d: DateTime, multiplier = 1) => d.plus({days: multiplier}),
    WEEK: (d: DateTime, multiplier = 1) => d.set({weekday: 1}).plus({weeks: multiplier}),
    MONTH: (d: DateTime, multiplier = 1) => d.set({day: 1}).plus({months: multiplier}),
    YEAR: (d: DateTime, multiplier = 1) => d.set({year: 1}).plus({years: multiplier}),
};

export function getExpectedDates(startDate: string | number | Date | DateTime, endDate: string | number | Date | DateTime, interval: Interval, timezone?: string): Date[] {
    const expectedDates: Date[] = [];
    let _startDate = toDateTime(startDate, {zone: timezone});
    const _endDate = toDateTime(endDate);
    while (_endDate.diff(_startDate).milliseconds > 0) {
        expectedDates.push(fromDateTimeToFormat().date(_startDate));
        _startDate = dateTimeNextExpectedDate[interval](_startDate);
    }
    return expectedDates;
}

export function getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
}

export function getDayOfWeek(year: number, month: number, date?: number) {
    return new Date(year, month - 1, date || 1).getDay();
}

export function getDateBeforeNow(interval: '7_DAYS' | '30_DAYS' | '60_DAYS' | '90_DAYS' | '180_DAYS'): string {
    const now = new Date();
    let beforeDate: number;
    switch (interval) {
        case '7_DAYS':
            beforeDate = now.setDate(now.getDate() - 7);
            break;
        case '30_DAYS':
            beforeDate = now.setDate(now.getDate() - 30);
            break;
        case '60_DAYS':
            beforeDate = now.setDate(now.getDate() - 60);
            break;
        case '90_DAYS':
            beforeDate = now.setDate(now.getDate() - 90);
            break;
        case '180_DAYS':
            beforeDate = now.setDate(now.getDate() - 180);
            break;
        default:
            console.warn('Invalid argument supplied to getDateBefore, falling back to 7 days');
            beforeDate = now.setDate(now.getDate() - 7);
    }

    return toDateTime(beforeDate).toFormat('yyyy-MM-dd');
}

export function getDateArray(startDate: Date, stopDate: Date): string[] {
    const dateArray: string[] = [];
    let currentDate = toDateTime(startDate);
    const parsedEndDate = toDateTime(stopDate);
    if (currentDate.toMillis() > parsedEndDate.toMillis()) {
        console.error('Start date is bigger than end date!');
        return [];
    }

    while (currentDate <= parsedEndDate) {
        dateArray.push(currentDate.toFormat('yyyy-MM-dd'));
        currentDate = currentDate.plus({days: 1});
    }
    return dateArray;
}

export function createDateTooltip(fc: FacilityChooserService, from: string, to?: string) {
    const date_from = toDateTime(from, {zone: fc.timezone}).toFormat('LLL dd, yyyy (ccc)');
    const date_to = to ? toDateTime(to, {zone: fc.timezone}).toFormat('LLL dd, yyyy (ccc)') : null;
    return `${date_from}${date_to ? ` - ${date_to}` : ''}`;
}

export function createIntervalTooltip(date: Date, interval: 'DAY' | 'WEEK' | 'MONTH', timezone: any) {
    if (interval === 'WEEK') {
        return `${toDateTime(date).toFormat('LLL dd, yyyy')} -
        ${toDateTime(new Date(date.setDate(date.getDate() + 7)), {zone: timezone}).toFormat('LLL dd, yyyy')}`;
    }
    if (interval === 'MONTH') {
        return `${toDateTime(date).toFormat('LLL dd, yyyy')} - ${toDateTime(new Date(date.setMonth(date.getMonth() + 1)), {zone: timezone}).toFormat('LLL dd, yyyy')}`;
    }
    return toDateTime(date).toFormat('LLL dd, yyyy');
}
