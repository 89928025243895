import {
    APIService,
    FacilitySerializer,
    OrganizationSerializer, OrganizationUserAuthSerializer,
} from '../@core/api.service';
import {ModelBase} from './model-base';
import {Facility} from './facility';

export class Organization extends ModelBase implements OrganizationSerializer, OrganizationUserAuthSerializer {
    description: string;
    facilities: Facility[];
    id: number;
    infection_report_types: string[];
    name: string;
    timezone: string;

    static viewSet = APIService.OrganizationViewSet;

    assign(x: OrganizationSerializer | Organization | OrganizationUserAuthSerializer) {
        super.assign(x);
        this.setListAccessor('facilities', Facility);
    }
}

export class OrganizationEdit implements OrganizationSerializer {
    id: number;
    name: string;
    facilities: FacilitySerializer[];

    getFacility(id: number) {
        for (const x of this.facilities) {
            if (x.id == id) return x;
        }
    }
}
