import {ModelBase} from './model-base';
import {
    APIService,
    FacilityDetailedSerializer,
    FacilityEmrImportSettingAccountSerializer,
    FacilityEmrUploadSettingsSerializer,
    FacilityLabImportAccountSerializer,
    FacilityPharmacyImportSettingAccountSerializer,
    FacilityPhysicianEMRImportSettingAccountSerializer,
    FacilityUserAuthSerializer,
    FacilityWithImportAccountsSerializer,
} from '../@core/api.service';
import {GetterCache} from '../utils/accessor.utils';

export interface Address {
    address?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    zip?: string;
}

export class Facility extends ModelBase implements FacilityDetailedSerializer, FacilityUserAuthSerializer {
    id: number;
    name: string;
    created_at: Date;
    size: number;
    timezone: string;
    type: string;
    address: string;
    organization: number;
    users_count: number;
    mac: number;
    import_settings: FacilityEmrImportSettingAccountSerializer;
    lab_import_account: number;
    city: string;
    is_inactive: boolean;
    state: string;
    zip_code: string;
    origin: string;
    from_customer_only: boolean;
    access_permission_type: string;
    phone_number: any;

    static viewSet = APIService.FacilityViewSet;

    assign(x) {
        super.assign(x);
        if (x.created_at && typeof x.created_at === 'string') x.created_at = new Date(x.created_at);
    }

    @GetterCache() get addressString() {
        return Facility.getAddressString(this);
    }

    get noEmr() {
        return ['ARSANA', 'BST'].includes(this.origin);
    }

    static getAddressString(address: Address, multiLine?: boolean): string;
    static getAddressString(address: string, city: string, state: string, zip: string, multiLine?: boolean): string;
    static getAddressString(address: Address | string, cityOrMultiline?: string | boolean, state?: string, zip?: string, multiLine?: boolean): string {
        let city = typeof cityOrMultiline === 'string' ? cityOrMultiline : null;
        multiLine = typeof cityOrMultiline === 'boolean' ? cityOrMultiline : (multiLine ?? true);
        if (typeof address === 'object') {
            city = address.city;
            state = address.state;
            zip = address.zip_code || address.zip;
            address = address.address;
        }

        return `${address || '?'}${multiLine ? '\n' : ', '}${city || '?'}, ${state || '?'} ${zip || '?'}`;
    }
}

export class FacilityDetails extends Facility implements FacilityWithImportAccountsSerializer {
    actual_hospice_payers_nums_critical_lt: number;
    actual_hospice_payers_nums_optimal_gt: number;
    employee: boolean;
    employee_enabled: boolean;
    emr_upload_account: number;
    facility_lab_import_account: Array<FacilityLabImportAccountSerializer>;
    fax_number: string;
    groups: number[];
    he_import_account: number;
    import_professions: any;
    include_secondary_payers_in_ccm_eligibility_calculation: boolean;
    lab_facility_origins: number[];
    lab_import_accounts: number[];
    lab_order_upload_facility_origin: number;
    pharmacy_import_settings: FacilityPharmacyImportSettingAccountSerializer;
    physician_emr_import_settings: FacilityPhysicianEMRImportSettingAccountSerializer;
    place_of_service: string;
    primary_source: string;
    rehospitalization_rate_critical_gt: number;
    rehospitalization_rate_optimal_lt: number;
    require_association_for_superadmins: boolean;
    sftp_upload_account: number;
    sftp_upload_folder: string;
    should_activate_fax: any;
    skilled_patients_beds_critical_lt: number;
    skilled_patients_beds_optimal_gt: number;
    unmatched: boolean;
    unmatched_enabled: boolean;
    upload_reports: any;
    upload_settings: FacilityEmrUploadSettingsSerializer;

    protected static cache_key = '__assignable_cache_2';
    static viewSet = APIService.FacilityViewSet;
}
