import {ModelBase} from './model-base';
import {
    APIService,
    NewPasswordRequestSerializer,
    PhysicianTeamMemberSerializer,
    PhysicianTeamMembershipSerializer,
    PhysicianTeamUserSerializer,
    SnomedConceptSerializer,
    UserFacilitySerializer,
} from '../@core/api.service';
import {Human} from './human';
import {Facility} from './facility';
import {MedicalSpecialty} from './medical-specialty';

export class PhysicianTeam extends ModelBase implements UserFacilitySerializer {
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    user: PhysicianTeamUser;
    origin: string;
    memberships: PhysicianTeamMembership[];
    medical_director: boolean;
    facility: Facility;
    is_rural: boolean;
    //@ts-ignore
    specialty: MedicalSpecialty;

    static viewSet = APIService.UserFacilityViewSet;

    assign(x: UserFacilitySerializer | PhysicianTeam) {
        super.assign(x);

        this.setAccessor('facility', Facility);
        this.setAccessor('user', PhysicianTeamUser);
        this.setAccessor('specialty', MedicalSpecialty);
        this.setListAccessor('memberships', PhysicianTeamMembership);
    }

    static isAssigned(team) {
        return team.user && !team.user.email.endsWith('arsana.import') && !team.user.is_placeholder;
    }

    get isAssigned() {
        return PhysicianTeam.isAssigned(this);
    }

    get users(): PhysicianTeamUser[] {
        const members = this.memberships.map(m => m.user);
        return this.user ? [this.user, ...members] : members;
    }

    get customer() {
        return this.user?.customer;
    }
}

export class PhysicianTeamUser extends ModelBase implements PhysicianTeamUserSerializer, PhysicianTeamMemberSerializer {
    id: number;
    customer: number;
    title_name: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    is_active: boolean;
    email: string;
    origin: string;
    password_requests: NewPasswordRequestSerializer[];
    profession_snomed: SnomedConceptSerializer;
    is_placeholder: boolean;
    // PhysicianTeamMemberSerializer
    npi_number?: string;
    has_npi?: boolean;

    get name() {
        return Human.getName(this);
    }

    get label() {
        return `${this.name} ${this.email}`;
    }

    assign(x: PhysicianTeamUserSerializer | PhysicianTeamMemberSerializer | PhysicianTeamUser) {
        super.assign(x);
    }
}

export class PhysicianTeamMembership extends ModelBase implements PhysicianTeamMembershipSerializer {
    id: number;
    care_team_lead: boolean;
    on_call: boolean;
    user: PhysicianTeamUser;

    assign(x: PhysicianTeamMemberSerializer | PhysicianTeamMembership) {
        super.assign(x);

        this.setAccessor('user', PhysicianTeamUser);
    }
}
