import {MedicalSpecialtySerializer} from '../@core/api.service';
import {ModelBase} from './model-base';

export class MedicalSpecialty extends ModelBase implements MedicalSpecialtySerializer {
    display_name: string;
    display_profession_name: string;
    id: number;
    name: string;
    profession_name: string;
    has_diagnostic_categorization: any;

    static clearCacheOnUserChange = false;
}
