export namespace Meta {
    export function get<T>(instance: {[key: string]: any}, key: string, defaultValue: T | (() => T) = null) {
        if (!instance[key]) {
            const value = defaultValue instanceof Function ? defaultValue() : defaultValue;

            Object.defineProperty(instance, key, {value, writable: true, enumerable: false});
        }
        return instance[key];
    }

    export function getObj(instance: {[key: string]: any}, key: string, defaultValue = {}) {
        return get(instance, key, defaultValue);
    }

    export function clearObj(instance: {[key: string]: any}, key: string, defaultValue = {}) {
        get(instance, key, defaultValue);
        instance[key] = defaultValue;
    }
}

export namespace AccessorUtils {
    const ACCESSOR_CACHE_KEY = '__accessorCache';

    export function getCache<T extends {[key: string]: any}>(instance: T, cacheKey = ACCESSOR_CACHE_KEY): {[keys in keyof T]: any} {
        return Meta.getObj(instance, cacheKey);
    }

    export function clearCache(instance: {[key: string]: any}) {
        Meta.clearObj(instance, ACCESSOR_CACHE_KEY);
        MethodUtils.clearCache(instance);
    }

    export function clearCacheKey<T extends {[key: string]: any}>(
        instance: T,
        key: keyof T,
        clearEntry: (cache: {[keys in keyof T]: any}) => void = cache => {
            cache[key] = undefined;
        }
    ) {
        const cache = getCache(instance);
        clearEntry(cache);
    }
}

export function GetterCache() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const getter = descriptor.get;

        if (!getter) return console.warn('No getter found to apply cacheGetter() to');

        descriptor.get = function () {
            const cache = AccessorUtils.getCache(this);

            if (!cache[propertyKey]) {
                cache[propertyKey] = getter.call(this);
            }

            return cache[propertyKey];
        };
    };
}

export namespace MethodUtils {
    export type CacheIdSegmentsFn = (...args) => (number | string)[];

    export const METHOD_CACHE_KEY = '__methodCache';

    export function getCache<T extends {[keys: string]: any}>(instance: T): {[keys in keyof T]: Map<any, any>} {
        return AccessorUtils.getCache(instance, METHOD_CACHE_KEY);
    }

    export function getCacheEntry<T extends {[keys: string]: any}>(instance: T, propertyKey: keyof T): {[keys in keyof T]: Map<any, any>} {
        const cache = getCache(instance);
        if (!cache[propertyKey]) {
            cache[propertyKey] = new Map();
        }
        return cache;
    }

    export function clearCache(instance: {[key: string]: any}) {
        Object.values(getCache(instance)).forEach(map => map.clear());
    }

    export function clearCacheKey<T extends {[key: string]: any}>(instance: T, key: keyof T) {
        AccessorUtils.clearCacheKey(instance, key, cache => {
            cache[key]?.clear();
        });
    }
}

/* Decorator private details */

export const cacheIdSegmentDelimiter = '|';
export const cacheIdSubSegmentDelimiter = ':';
const convertArgToCacheIdSegment = arg => arg?.id ?? arg;
const convertArgsToCacheId = (...args) => args.map(arg => convertArgToCacheIdSegment(arg)).join(cacheIdSegmentDelimiter);

const cacheIdGenerators = {
    convert: (...args) => args.length === 1 ? convertArgToCacheIdSegment(args[0]) : convertArgsToCacheId(...args),
    convertObject: (...args) => ['string', 'number'].includes(typeof args[0]) ?
        args[0] :
        convertArgsToCacheId(Object.entries(args[0]).map(x => x.join(cacheIdSubSegmentDelimiter))),
    reference: (...args) => args[0],
};
type CacheIdStrategy = keyof typeof cacheIdGenerators;

/* Decorator */

/**
 * Decorate methods to cache the first non-nullish evaluated value and return the cached value on subsequent invocations.
 *
 * @param cacheIdStrategy specify how the cache id will be generated for the cached value.
 *  - 'convert' is designed to be a smart procedure of generating a cache id based on one or multiple arguments.
 *  If an argument is a primitive, then its value will be used as a cache id segment.
 *  If it is an Object, then its "id" property will be used if there is one.
 *  If it doesn't have an "id" property, then the Object itself will be the cache id - which only works when there's a single argument.
 *  This is the default behavior.
 *  - 'convertObject' works in case of a single argument.
 *  In case of an Object it generates cache id segments from the properties of the Object in a similar manner as the 'convert' strategy
 *  does for separate arguments.
 *  If the argument is a primitive, then its value will be used as the cache id.
 *  - 'reference' is usable when the decorated method has one argument. In case of a non-primitive value the Object reference will be the
 *  cache id regardless of whether it has an "id" property or not.
 *  - A function can be provided to generate segments from the arguments which will then be joined with a delimiter to create the cache id.
 */
export function MethodCache();
export function MethodCache(cacheIdStrategy: CacheIdStrategy);
export function MethodCache(cacheIdSegmentsFn: MethodUtils.CacheIdSegmentsFn);
export function MethodCache(cacheIdStrategyOrSegmentsFn: CacheIdStrategy | MethodUtils.CacheIdSegmentsFn = 'convert') {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        if (!cacheIdStrategyOrSegmentsFn) throw new Error('[Accessor utils] - MethodCache - invalid cache ID generator configuration');

        const cacheIdGenerator = typeof cacheIdStrategyOrSegmentsFn === 'string' ?
            cacheIdGenerators[cacheIdStrategyOrSegmentsFn] :
            (...args) => cacheIdStrategyOrSegmentsFn(...args).join(cacheIdSegmentDelimiter);

        const method = descriptor.value;
        descriptor.value = function (...args) {
            const cache = MethodUtils.getCacheEntry(this, propertyKey);
            const cacheId = cacheIdGenerator(...args);

            let result = cache[propertyKey].get(cacheId);
            if (!result) {
                result = method.apply(this, args);
                cache[propertyKey].set(cacheId, result);
            }
            return result;
        };
    };
}
