import {ModelBase} from './model-base';
import {APIService, ValueSetValueSerializer, ValueSetViewSetQueryParams} from '../@core/api.service';
import {shareReplay} from 'rxjs/operators';
import {MethodCache} from '../utils/accessor.utils';
import {Observable} from 'rxjs';
import {VALUESET_OIDS} from '../@core/valueset_oids';

export type ValueSetCategory = keyof typeof VALUESET_OIDS;

export interface ValueSetFilters extends ValueSetViewSetQueryParams {
    category?: ValueSetCategory;
}

export class CodedValue extends ModelBase implements ValueSetValueSerializer {
    id: number;
    name: string;
    code: string;
    display_name: string;
    same_as: string;
    value_set_oid: number;

    static clearCacheOnUserChange = false;
    static viewSet = APIService.ValueSetViewSet;

    assign(x) {
        super.assign(x);
        this.name = this.display_name;
    }

    @MethodCache('convertObject')
    static list(filters: ValueSetFilters): Observable<CodedValue[]> {
        return super.list(filters).pipe(shareReplay(1));
    }

    static getViewSet(category: ValueSetCategory) {
        return {
            list: (filters?: ValueSetFilters) => CodedValue.list({...filters, category}),
            retrieve: (id: number) => CodedValue.retrieve(id) as Observable<CodedValue>,
        };
    }
}
