var makeIterator = require('../function/makeIterator_');

/**
 * Array every
 */
function every(arr, callback, thisObj) {
  callback = makeIterator(callback, thisObj);
  var result = true;
  if (arr == null) {
    return result;
  }
  var i = -1,
    len = arr.length;
  while (++i < len) {
    // we iterate over sparse items since there is no way to make it
    // work properly on IE 7-8. see #64
    if (!callback(arr[i], i, arr)) {
      result = false;
      break;
    }
  }
  return result;
}
module.exports = every;