import {NgZone} from '@angular/core';
import {Subject} from 'rxjs';

export class BroadcastChannelBase<T, K = any> extends Subject<{action: T; payload?: K}> {
    private _broadcastChannel: BroadcastChannel;

    constructor(private broadCastChannelName: string, private zone: NgZone) {
        super();

        // e.g. because of earlier versions of Safari
        if (window.BroadcastChannel) {
            this._broadcastChannel = new BroadcastChannel(this.broadCastChannelName);
            this._broadcastChannel.onmessage = message => {
                this.zone.run(() => {
                    super.next(message.data);
                });
            };
        }
    }

    next(value: {action: T; payload?: K}) {
        if (this._broadcastChannel) {
            this._broadcastChannel.postMessage(value);
        } else {
            super.next(value);
        }
    }
}
