<div class="modal-header">
    <h4 class="modal-title">Enter Security Code</h4>
</div>
<div class="modal-body">
    <form #f="ngForm" (submit)="submit()">
        <p>A one-time security code is needed to access this feature, which was sent to you in {{passcodeInfo?.two_factor_authentication_type|constant}}.</p>
        <div class="form-group">
            <input placeholder="Enter Security Code" [(ngModel)]="passcode" name="auth-code" type="text" class="form-control">
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" (click)="submit()">Verify</button>
</div>
