import {ModelBase} from './model-base';
import {APIService, GroupSerializer} from '../@core/api.service';
import {Facility} from './facility';
import {Modify} from '../utils/type.utils';

export class Group extends ModelBase implements Modify<GroupSerializer, {facilities: any}> {
    id: number;
    name: string;
    description: string;
    facilities: Facility[];

    static viewSet = APIService.GroupViewSet;

    assign(x: GroupSerializer) {
        super.assign(x);

        this.setListAccessor('facilities', Facility);
    }
}
