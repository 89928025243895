import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {APIService, Endpoint, EndpointArgs} from '../../../../@core/api.service';
import {EMPTY, Observable, Subject, throwError} from 'rxjs';
import {TwoFactorAuthComponent} from './two-factor-auth.component';
import {ToastService} from '../../../../@core/toast.service';
import {catchError, finalize, switchMap, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Constants} from '../../../../@core/constants';
import {extractErrorMessage} from '../../../../utils/error.utils';
import {LocalStorage} from 'ngx-webstorage';

@Injectable({
    providedIn: 'root',
})
export class TwoFactorAuthService {
    @LocalStorage() passcode: string;
    passcode$: Subject<string>;
    passcodeError$ = new Subject<void>();
    modal: NgbModalRef;

    constructor(private modalService: NgbModal,
                private apiService: APIService,
                private toastService: ToastService,
                private constants: Constants) {
    }

    openTwoFactorAuthModal(): Observable<string> {
        this._setSubject(new Subject());

        this.apiService.GeneratePassCodeView.get().subscribe(x => {
            this.modal = this.modalService.open(TwoFactorAuthComponent);
            this.modal.closed.pipe(take(1)).subscribe(() => this._setSubject(null));
            (this.modal.componentInstance as TwoFactorAuthComponent).passcodeInfo = x;
        }, err => {
            this._setSubject(null);
            this.toastService.error(extractErrorMessage(err, 'send passcode for authorization'));
        });

        return this.passcode$.asObservable().pipe(
            take(1),
            finalize(() => this.closeModal()),
        );
    }

    addAuth<T>(endPoint: Endpoint<T>, ...args: EndpointArgs): Observable<T> {
        return this.openTwoFactorAuthModal().pipe(
            switchMap(passcode => {
                const argsWithHeader: any[] = [...args];
                let headerI: number;
                if (typeof args[0] === 'number') {
                    if (endPoint.name.includes('update')) {
                        headerI = 3;
                    } else {
                        headerI = 2;
                    }
                } else if (endPoint.name.includes('create')) {
                    headerI = 2;
                } else {
                    headerI = 1;
                }
                argsWithHeader[headerI] = {...(argsWithHeader[headerI] || {}), passcode};
                return endPoint(...argsWithHeader).pipe(
                    catchError((err: HttpErrorResponse) => {
                        if (err.status === 403 && Object.values(this.constants.getConstSetSync('TWO_FACTOR_AUTH_ERROR')).includes(err.error.detail)) {
                            this.passcodeError$.next();
                            return EMPTY;
                        }
                        this.closeModal();
                        return throwError(err);
                    }),
                );
            }),
        );
    }

    addAuthToFunction<T>(endpoint: (args, passcode) => Observable<any>, ...args: EndpointArgs): Observable<T> {
        return this.openTwoFactorAuthModal().pipe(
            switchMap(passcode => endpoint(args, passcode).pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 403 && Object.values(this.constants.getConstSetSync('TWO_FACTOR_AUTH_ERROR')).includes(err.error.detail)) {
                        this.passcodeError$.next();
                        return EMPTY;
                    }
                    this.closeModal();
                    return throwError(err);
                }),
            )),
        );
    }

    submitPasscode(passcode: string) {
        this.passcode$.next(passcode);
    }

    closeModal() {
        this.modal?.close();
    }

    private _setSubject(subj: Subject<any>) {
        this.passcode$?.complete();
        this.passcode$ = subj;
    }
}
