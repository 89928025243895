import {ModelBase} from './model-base';
import {MedicalResultTypeSerializer} from '../@core/api.service';

export class MedicalResultType extends ModelBase implements MedicalResultTypeSerializer {
    static clearCacheOnUserChange = false;

    id: number;
    name: string;
    number_of_values: number;
    matrix_separator: string;
    unit: string;
    critical: number;
    priority: number;
}
