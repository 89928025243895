import {Injectable, NgZone} from '@angular/core';
import {
    fromEvent,
    merge,
    MonoTypeOperatorFunction,
    ReplaySubject,
    Subject,
    Subscription,
    throwError,
    timer,
} from 'rxjs';
import {
    catchError,
    take,
    takeUntil,
    filter,
    retryWhen,
    shareReplay,
    skipUntil,
    share,
    mapTo,
} from 'rxjs/operators';
import {APIService} from './api.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {ActiveToast} from 'ngx-toastr';
import {PARAMETERS} from './parameters';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from './toast.service';
import {SentryErrorHandler} from './sentry-error-handler';
import {AuthBroadcastChannelMessageActions, AuthBroadcastChannelPayload, AuthBroadcastService} from './auth-broadcast-serivce';

export type LoginModalResult = 'update_tokens' | 'logout' | 'dismiss';

export interface NewAuthUser {
    id: number;
    email: string;
    title_name: string;
    first_name: string;
    middle_name: string;
    last_name: string;
}

@Injectable({
    providedIn: 'root',
})
export class JWTService {
    static get tokenExpiration() {
        return PARAMETERS.jwt_expiration;
    }

    static get refreshExpiration() {
        return PARAMETERS.jwt_refresh_expiration;
    }

    static tokenSubject$: Subject<string> = new Subject();

    get tokenSubject$() {
        return JWTService.tokenSubject$;
    }

    get hasUser() {
        return !!this.userId;
    }

    authToken: string;
    refreshToken: string;
    userId: number;
    refreshTime: number;
    tokenRefreshInProgress = false;
    refreshToast: ActiveToast<any>;
    loginModal: NgbModalRef;
    switchUserModal: NgbModalRef;

    loginModalSubscription: Subscription = null;
    invalidateTokens$ = new Subject<boolean>();
    showToast$ = new Subject<boolean>();
    loginRequest$ = new Subject<Subject<any>>();
    forceRefresh$: Subscription;
    refresherToast$: Subscription;
    newUser$ = new ReplaySubject<NewAuthUser>(1);

    private _loginModalResult$: Subject<LoginModalResult>;

    constructor(private router: Router,
                private location: Location,
                private api: APIService,
                private toastService: ToastService,
                private zone: NgZone,
                private modalService: NgbModal,
                private sentryErrorHandler: SentryErrorHandler,
                private authBroadcastService: AuthBroadcastService) {
        if (environment.test) (window as any).JWTService = JWTService;

        this.authBroadcastService.subscribe(x => this._handleTokenBroadcast(x.action, x.payload));

        this.authToken = localStorage.getItem('jwt-token');
        this.refreshToken = localStorage.getItem('jwt-refresh-token');
        this.refreshTime = parseInt(localStorage.getItem('jwt-refresh-time'));

        this.invalidateTokens$.subscribe(() => {
            this.setToken('', '', false);
            this.closeToast();
            this._closeModals();
        });

        if (this.authToken) {
            this.attachTokenRefreshers();
        }

        this.tokenSubject$.pipe(
            // If a modal subscription is currently alive
            // We let it to handle the token updates
            filter(() => !this.loginModalSubscription)
        ).subscribe(token => {
            this.attachTokenRefreshers();
            this.closeToast();

            if (token) {
                this.loginModalSubscription = null;
            }
        });
    }

    isSameUser(userId: number) {
        return userId === this.userId;
    }

    attachTokenRefreshers() {
        this.attachInteractionRefresher();
        this.attachRefresherToast();
    }

    detachTokenRefreshers() {
        if (this.forceRefresh$) this.forceRefresh$.unsubscribe();
        if (this.refresherToast$) this.refresherToast$.unsubscribe();
    }

    attachInteractionRefresher() {
        if (this.forceRefresh$) this.forceRefresh$.unsubscribe();

        if (!this.refreshTime || !this.refreshToken) return;

        this.forceRefresh$ = merge(
            fromEvent(window, 'keyDown'),
            fromEvent(window, 'click')
        ).pipe(
            takeUntil(
                merge(
                    this.tokenSubject$,
                    this.invalidateTokens$
                )
            ),
            skipUntil(timer((this.refreshTime + 1000 * JWTService.tokenExpiration) - Date.now())),
            take(1)
        ).subscribe(() => {
            this.refreshTokens();
        });
    }

    attachRefresherToast() {
        if (this.refresherToast$) this.refresherToast$.unsubscribe();

        if (!this.refreshTime || !this.refreshToken) return;

        merge(
            timer(
                1000 * (JWTService.refreshExpiration - 3 * 60) // 3 min before refresh expires
            ).pipe(mapTo(true)),
            this.showToast$
        ).pipe(
            takeUntil(
                merge(
                    this.tokenSubject$,
                    this.invalidateTokens$
                )
            ),
            take(1),
        ).subscribe(broadcast => {
            // Do not display multiple toasts
            if (this.refreshToast || this._loginModalResult$ || this.switchUserModal) return;
            if (broadcast) {
                this.authBroadcastService.next({action: 'show_toast'});
            }

            this.refreshToast = this.toastService.warning(
                `
            <div class="test-refresh-toast">
                <div>
                    You haven't made any interaction in a while.
                    Click the button below if you don't want to be automatically signed out.
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <span class="btn btn-sm btn-white">Stay signed in</span>
                </div>
            </div>`,
                'Are you still there?',
                {
                    enableHtml: true,
                    messageClass: 'cursor-pointer',
                    disableTimeOut: true,
                });

            if (this.refreshToast) {
                this.refreshToast
                    .onTap
                    .pipe(take(1))
                    .subscribe(() => {
                        this.refreshTokens();
                    });
            }
        });
    }

    refreshTokens() {
        if (this.tokenRefreshInProgress || this.switchUserModal) return;

        if (!this.loginModalSubscription) this.tokenRefreshInProgress = true;

        this.detachTokenRefreshers();
        const refresh$ = this.api.TokenRefreshView
            .post({token: this.refreshToken})
            .pipe(
                share(),
                catchError((err1: HttpErrorResponse, _) => {
                    if (this.loginModalSubscription || !this.hasUser) {
                        return throwError(err1);
                    }

                    if (!this.tokenRefreshInProgress) { // Token refresh is not in progress anymore, user should be logged out
                        this.logOutUser();
                        return throwError(err1);
                    }

                    this._showLoginModal(err1);

                    this.sentryErrorHandler.handleError(err1);

                    return merge(
                        this.tokenSubject$,
                        this.invalidateTokens$
                    ).pipe(take(1));
                })
            );

        refresh$.subscribe(resp => {
            if (typeof resp === 'string' || typeof resp === 'boolean') return;
            const {token, refresh_token} = resp;
            if (!token && !refresh_token) return;
            this.tokenRefreshInProgress = false;
            this.setToken(token, refresh_token);
            this.updateTokenSubject();
        });

        return refresh$;
    }

    setToken(authToken: string, refreshToken: string, broadcast = true) {
        this.authToken = authToken;
        this.refreshToken = refreshToken;
        localStorage.setItem('jwt-token', authToken);
        localStorage.setItem('jwt-refresh-token', refreshToken);

        if (authToken && refreshToken) {
            localStorage.setItem('jwt-refresh-time', `${Date.now()}`);
            this.refreshTime = Date.now();
            this.tokenRefreshInProgress = false;
        } else {
            localStorage.removeItem('jwt-refresh-time');
            this.refreshTime = null;
        }

        this.updateTokenSubject();
        this.attachTokenRefreshers();
        if (broadcast) {
            this.authBroadcastService.next({
                action: 'update_tokens',
                payload: {
                    user: {
                        id: this.userId,
                    },
                    authToken,
                    refreshToken,
                },
            });
        }
    }

    updateTokenSubject() {
        this.tokenSubject$.next(this.authToken);
    }

    closeToast() {
        if (this.refreshToast) {
            this.refreshToast.toastRef.close();
            this.refreshToast = null;
        }
    }

    logOutUser() {
        if ((this.tokenRefreshInProgress || this.loginModalSubscription) && this.hasUser) return;

        this.detachTokenRefreshers();
        this.closeToast();
        this.modalService.dismissAll();

        // We use window.location here, because angular location couldn't be injected
        // in portal service and I wanted it to be consistent between components
        this.invalidateTokens$.next(false);
    }

    static retryOnTokenChange<T>(): MonoTypeOperatorFunction<T> {
        return input$ => input$.pipe(retryWhen(() => this.tokenSubject$.pipe(filter(token => !!token))));
    }

    retryOnTokenChange<T>(): MonoTypeOperatorFunction<T> {
        return JWTService.retryOnTokenChange<T>();
    }

    static cacheOnce<T>(): MonoTypeOperatorFunction<T> {
        return input$ => input$.pipe(this.retryOnTokenChange(), shareReplay(1));
    }

    cacheOnce<T>(): MonoTypeOperatorFunction<T> {
        return JWTService.cacheOnce<T>();
    }

    private _showLoginModal(err, broadcast = true) {
        if (this.switchUserModal || !this.hasUser) return;

        this.tokenRefreshInProgress = false;

        if (broadcast) {
            this.authBroadcastService.next({
                action: 'show_login_modal',
                payload: {
                    user: {
                        id: this.userId,
                    },
                },
            });
        }

        // Hover actions could cause the modal to open
        // So we need to make sure it runs inside the angular zone
        this.zone.run(() => {
            this.closeToast();
            this._loginModalResult$ = new Subject<LoginModalResult>();
            this.loginRequest$.next(this._loginModalResult$);

            this.loginModalSubscription = this._loginModalResult$
                .pipe(
                    take(1),
                    takeUntil(this.invalidateTokens$),
                )
                .subscribe(action => {
                    if (action === 'update_tokens') {
                        this.authBroadcastService.next({
                            action: 'dismiss_login_modal',
                            payload: {
                                user: {
                                    id: this.userId,
                                },
                                authToken: this.authToken,
                                refreshToken:
                                this.refreshToken,
                            },
                        });
                        this._cleanupLoginModal();
                    } else if (action === 'logout') {
                        if (this.switchUserModal) {
                            return throwError(null);
                        }

                        this.loginModalSubscription = null;
                        this._loginModalResult$ = null;
                        this.loginModal = null;
                        this.logOutUser();
                        this.closeToast();
                        return throwError(err);
                    } else {
                        this._closeModals();
                        return throwError(null);
                    }
                });
        });
    }

    private _closeModals() {
        if (this.modalService.hasOpenModals()) {
            this.loginModalSubscription?.unsubscribe();
            if (this._loginModalResult$) {
                this._loginModalResult$.next('dismiss');
            }
            if (this.loginModal) {
                this.loginModal.dismiss('dismiss');
            }
            if (this.switchUserModal) {
                this.switchUserModal.dismiss();
            }
            this._cleanupLoginModal();
        }
    }

    private _cleanupLoginModal() {
        this.loginModalSubscription = null;
        this.tokenRefreshInProgress = false;
        this._loginModalResult$ = null;
        this.loginModal = null;

        this.closeToast();
    }

    private async _displaySwitchUserModal(user) {
        this.closeToast();

        this.newUser$.next(user);
        if (!this.switchUserModal) {
            this.detachTokenRefreshers();
            const component = await import('../@theme/components/switch-user-modal/switch-user-modal.component').then(m => m.SwitchUserModalComponent);
            this.switchUserModal = this.modalService.open(
                component,
                {
                    backdrop: 'static',
                    keyboard: false,
                }
            );

            this.switchUserModal
                .result
                .finally(() => {
                    this.switchUserModal = null;
                    this.newUser$.next(null);
                    this.attachTokenRefreshers();
                });
        }
    }

    private _handleTokenBroadcast(action: AuthBroadcastChannelMessageActions, payload?: AuthBroadcastChannelPayload) {
        switch (action) {
            case 'new_user':
                if (this.hasUser && !this.isSameUser(payload.user.id)) {
                    this._displaySwitchUserModal(payload.user);
                } else {
                    if (this._loginModalResult$) this._loginModalResult$.next('dismiss');
                    if (this.switchUserModal) this.switchUserModal.dismiss();
                    if (this.loginModal) this.loginModal.dismiss();
                }
                if (payload.authToken && payload.refreshToken) {
                    this.setToken(payload.authToken, payload.refreshToken, false);
                }
                break;
            case 'update_tokens':
                if (this.hasUser && this.isSameUser(payload.user.id)) {
                    this.setToken(payload.authToken, payload.refreshToken, false);
                    this.closeToast();
                }
                break;
            case 'invalidate_tokens':
                this.invalidateTokens$.next(true);
                break;
            case 'show_toast':
                if (this.hasUser) {
                    this.showToast$.next(false);
                }
                break;
            case 'show_login_modal':
                if (this.hasUser && this.isSameUser(payload.user.id) && !this._loginModalResult$) {
                    this._showLoginModal('Error received from other browser tab', false);
                }
                break;
            case 'dismiss_login_modal':
                if (this.hasUser && this.isSameUser(payload.user.id)) {
                    if (this._loginModalResult$) this._loginModalResult$.next('dismiss');
                    if (this.loginModal) this.loginModal.dismiss();
                    this.setToken(payload.authToken, payload.refreshToken);
                }
                break;
        }
    }
}
