import {ModelBase} from './model-base';
import {APIService, DrugNameSerializer, DrugSerializer} from '../@core/api.service';

export class DrugName extends ModelBase implements DrugNameSerializer {
    id?: number;
    name?: string;
    drug?: DrugSerializer[];
    type?: string;

    static viewSet = APIService.DrugNameViewSet;
}
