export function disableBodyScroll() {
    document.body.style.overflow = 'hidden';
}

export function enableBodyScroll() {
    document.body.style.overflow = '';
}

export function compare(vA: number | string, vB: number | string, desc = false): number {
    if (typeof vA === 'string') vA = vA.toUpperCase();
    if (typeof vB === 'string') vB = vB.toUpperCase();
    if (vA == null && vB == null) return 0;
    if (vA == null) return desc ? 1 : -1;
    if (vB == null) return desc ? -1 : 1;
    if (vA < vB) return desc ? 1 : -1;
    if (vA > vB) return desc ? -1 : 1;
    return 0;
}

export function compareObjects(a: any, b: any, prop: ((x) => number | string) | number | string | symbol = 'name', desc = false): number {
    const vA = typeof prop === 'number' || typeof prop === 'string' || typeof prop === 'symbol' ? a[prop] : prop(a);
    const vB = typeof prop === 'number' || typeof prop === 'string' || typeof prop === 'symbol' ? b[prop] : prop(b);
    return compare(vA, vB, desc);
}

export function getSorted<T = any>(array: T[], prop: ((x) => number | string) | number | string | symbol = 'name', desc = false): T[] {
    return array.sort((a, b) => compareObjects(a, b, prop, desc));
}

export function compareDates(aDate: string | Date, bDate: string | Date, desc = true): number {
    return ((new Date(aDate).getTime() || 0) - (new Date(bDate).getTime() || 0)) * (desc ? -1 : 1);
}

export function sortByDate<T = any>(array: T[], dateKey = 'date', desc = true): T[] {
    return array.sort((a, b) => compareDates(a[dateKey], b[dateKey], desc));
}

export function comparePeriod(a: any, b: any, startDateKey = 'start_date', endDateKey = 'end_date', desc = true): number {
    return compareDates(a[endDateKey] || new Date(), b[endDateKey] || new Date(), desc) || compareDates(a[startDateKey], b[startDateKey], desc);
}

export function sortByPeriod<T = any>(array: T[], startDateKey = 'start_date', endDateKey = 'end_date', desc = true): T[] {
    return array.sort((a, b) => comparePeriod(a, b, startDateKey, endDateKey, desc));
}

export function compareEndOrStart(a: any, b: any, startDateKey = 'start_date', endDateKey = 'end_date', desc = true): number {
    return compareDates(a[endDateKey] || a[startDateKey], b[endDateKey] || b[startDateKey], desc) || compareDates(a[startDateKey], b[startDateKey], desc);
}

export function sortByEndOrStart<T = any>(array: T[], startDateKey = 'start_date', endDateKey = 'end_date', desc = true): T[] {
    return array.sort((a, b) => compareEndOrStart(a, b, startDateKey, endDateKey, desc));
}

export function periodsIntersect(aStart: Date | string | number, aEnd: Date | string | number, bStart: Date | string | number, bEnd: Date | string | number) {
    const as = typeof aStart === 'number' ? aStart : new Date(aStart).getTime();
    const bs = typeof bStart === 'number' ? bStart : new Date(bStart).getTime();
    const ae = typeof aEnd === 'number' ? aEnd : (aEnd ? new Date(aEnd) : new Date()).getTime();
    const be = typeof bEnd === 'number' ? bEnd : (bEnd ? new Date(bEnd) : new Date()).getTime();
    return (as <= be && bs <= ae);
}

export function getAddressLine(address: {line: string; line_2: string}): string[] {
    let addresses = [];
    if (address.line && address.line_2) {
        if (Array.isArray(address.line)) {
            if (address.line.includes(address.line_2)) {
                addresses = [...address.line];
            } else {
                addresses = [...address.line, address.line_2];
            }
        } else {
            addresses = [address.line, address.line_2];
        }
    } else if (Array.isArray(address.line)) {
        addresses = address.line;
    } else {
        addresses = [address.line];
    }
    return addresses;
}
