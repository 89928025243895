import {Injectable} from '@angular/core';
import {APIService, ICDCodeGroupSerializer} from './api.service';
import {JWTService} from './jwt.service';
import {filter, map, switchMap} from 'rxjs/operators';
import {getId} from '../utils/type.utils';
import {Observable, of} from 'rxjs';
import distinctColors from 'distinct-colors';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UserAuthService} from './user-auth.service';
import {ICDCode, ICDCodeGroup} from '../models/ICD-code';
import {assignOrCreate} from '../models/model-base';

export type IcdGroupSet = {[groupSetKey: number]: ICDCodeGroup[]};

@Injectable({
    providedIn: 'root',
})
export class ICDCodeGroupService {
    static defGroupSetKey = 3;
    private static _groupSets: IcdGroupSet;
    private static _groupSets$: Observable<IcdGroupSet>;

    static get groupSets(): Observable<IcdGroupSet> {
        if (!this._groupSets$) {
            this._groupSets$ = APIService.ICDCodeGroupViewSet.list().pipe(
                map(groups => {
                    ICDCodeGroupService._groupSets = groups.results.reduce((r, g) => {
                        if (!r[g.category]) r[g.category] = [];
                        r[g.category].push(assignOrCreate(ICDCodeGroup, g));
                        return r;
                    }, {} as IcdGroupSet);
                    for (const key in ICDCodeGroupService._groupSets) {
                        const palette = distinctColors({count: ICDCodeGroupService._groupSets[key].length, chromaMin: 65});
                        ICDCodeGroupService._groupSets[key].forEach((g, i) => {
                            g.color = 'rgba(';
                            palette[i].rgb(true).map((c, j) => g.color += j < palette[i].rgb(true).length - 1 ? `${c}, ` : `${c})`);
                        });
                    }
                    return ICDCodeGroupService._groupSets;
                }),
                JWTService.cacheOnce(),
            );
        }

        return this._groupSets$;
    }

    get groupSets() {
        return ICDCodeGroupService.groupSets;
    }

    constructor(private userAuth: UserAuthService) {
    }

    static getIcdCodeGroup(code: ICDCode, groupSetKey = this.defGroupSetKey) {
        if (!code.icd_groups) return null;

        const gIds: number[] = code.icd_groups.map(x => getId(x)).filter(x => typeof x === 'number');
        if (!gIds || !gIds.length) return null;

        return this._groupSets[groupSetKey].find(x => gIds.includes(x.id)) || null;
    }

    static getIcdCodeColor(code: any, groupSetKey = this.defGroupSetKey) {
        const g = this.getIcdCodeGroup(code, groupSetKey);

        return g ? g.color : null;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICDCodeGroupSerializer> {
        return this.userAuth.user.pipe(
            filter(u => !!u),
            switchMap(u => u.isSuspended ? of(null) : this.groupSets),
        );
    }
}
