export const TIMEOUT_HTTP_STATUSES = [408, 504];

export const TIMEOUT_ERROR_MESSAGE = 'Request timed out. For best results, adjust the filters to narrow your search.';

function parseString(x: any): string | null {
    if (typeof x === 'string') return x;

    if (Array.isArray(x) && typeof x[0] === 'string') return x.join('. ');

    return null;
}

export function extractErrorMessage(error: any, taskDescription?: string, defaultMessage?: string, lookForMessage = false, additionalLookUpKeys: string[] = []): string {
    let es = error?.error ? extractErrorMessage(error.error, taskDescription, defaultMessage, true, additionalLookUpKeys) : parseString(error);

    if (es && es.length < 2000 && ['<!DOCTYPE', '<!doctype', '<html'].every(x => !es.startsWith(x) && !es.startsWith(`\n${x}`))) return es;

    const lookUpProps = ['detail', 'details', ...additionalLookUpKeys];
    if (lookForMessage) lookUpProps.push('message');

    for (const key of lookUpProps) {
        es = parseString(error[key]);
        if (es) return es;
    }

    if (TIMEOUT_HTTP_STATUSES.includes(error?.status)) return TIMEOUT_ERROR_MESSAGE;

    if (defaultMessage) return defaultMessage;

    return taskDescription ? `An error occurred while trying to ${taskDescription}` : 'There was an error';
}
