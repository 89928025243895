import {Injectable} from '@angular/core';
import {APIService, PhysicianTeamMembershipSerializer, TaskActionSerializer, UserAuthSerializer} from './api.service';
import {User} from '../models/user';
import {ReplaySubject, Observable, of, forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';
import {Constants} from './constants';
import {filter, take, map, shareReplay, switchMap, catchError} from 'rxjs/operators';
import {JWTService, NewAuthUser} from './jwt.service';
import {ModelBase} from '../models/model-base';
import {PhysicianTeam} from '../models/physician-team';
import {getId} from '../utils/type.utils';
import {SentryErrorHandler} from './sentry-error-handler';
import {AuthBroadcastService} from './auth-broadcast-serivce';
import {AccessorUtils, GetterCache, MethodCache, MethodUtils} from '../utils/accessor.utils';
import {SessionStorageService} from 'ngx-webstorage';
import {Patient, RelatedPatient} from '../models/patient';
import {TwoFactorAuthService} from '../portal/@portal-theme/components/two-factor-auth/two-factor-auth.service';

const physicianTeamsCacheIdSegmentsFn: MethodUtils.CacheIdSegmentsFn = args => [
    (args.physician_teams || []).join(','),
    getId(args.physician),
    getId(args.facility),
];

@Injectable({
    providedIn: 'root',
})
export class UserAuthService {
    private static _user: User;
    user = new ReplaySubject<User>(1);

    constructor(private api: APIService,
                private jwt: JWTService,
                private router: Router,
                private permissionService: NgxPermissionsService,
                private constants: Constants,
                private sentryErrorHandler: SentryErrorHandler,
                private authBroadcastService: AuthBroadcastService,
                private twoFactorAuthService: TwoFactorAuthService,
                private sessionStorage: SessionStorageService) {
        this.updateUserInfo().then(() => {
        }).catch(() => {
        });

        this.jwt.invalidateTokens$.subscribe(() => {
            if (UserAuthService.hasUser) this.logout();
        });
    }

    static get hasUser() {
        return !!UserAuthService._user;
    }

    static get userId() {
        return UserAuthService._user?.id || null;
    }

    static isSameUser(userId: number) {
        return userId === getId(UserAuthService._user);
    }

    updateUserInfo(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.api.AuthCurrentUser.get().subscribe(resp => {
                this._handleUserResponse(resp);
                resolve();
            },
            err => {
                UserAuthService._user = null;
                this.user.next(UserAuthService._user);
                reject(err.error);
                this.sentryErrorHandler.handleError(err);
            }
            );
        });
    }

    private _handleUserResponse(resp: UserAuthSerializer) {
        this.permissionService.flushPermissions();
        if (resp.permissions) {
            this.permissionService.loadPermissions(resp.permissions);
            if (UserAuthService._user && resp.id !== UserAuthService._user.id) this.clearCache();
            this._setUser(new User(resp));
            this._broadcastUser();
        }
    }

    private _setUser(user: User) {
        UserAuthService._user = user;
        this.user.next(UserAuthService._user);
        this.jwt.userId = user?.id;
    }

    login(email: string, password: string, reAuthentication = false): Promise<void> {
        return new Promise((resolve, reject) => {
            this.api.TokenObtainPairView.post({email, password})
                .subscribe(
                    resp => {
                        if (resp['token']) {
                            this.jwt.setToken(resp['token'], resp['refresh_token'], false);
                            if (reAuthentication) {
                                resolve();
                                this._broadcastUser(resp['token'], resp['refresh_token']);
                                return;
                            }
                            this.updateUserInfo().then(() => {
                                resolve();
                            }).catch(err => reject(err.error));
                        }
                    },
                    err => reject(err.error)
                );
        });
    }

    logout() {
        setTimeout(() => {
            this.permissionService.flushPermissions();
            this.jwt.setToken('', '', false);
            this.clearCache();
            this.sessionStorage.clear(`system-alerts-${UserAuthService._user.id}`);
            this._setUser(null);
            this.router.navigate(['/login']);
        });
    }

    canEditUser(u: User): Observable<boolean> {
        return Observable.create(obs => {
            if (!u.roles) {
                obs.next(undefined);
                obs.complete();
            } else {
                this.constants.getConstSet('ROLE_').subscribe(roles => {
                    obs.next(this._getDominantRoleIndex(UserAuthService._user, roles) <= this._getDominantRoleIndex(u, roles));
                    obs.complete();
                });
            }
        });
    }

    getRolesWhichUserCanGrant(): Observable<any> {
        return Observable.create(obs => {
            if (!UserAuthService._user.roles) {
                obs.next(undefined);
                obs.complete();
            } else {
                this.constants.getConstSet('ROLE_').subscribe(roles => {
                    const dominant_role = this._getDominantRoleIndex(UserAuthService._user, roles);
                    const r = Object.keys(roles).filter((v, i, arr) => i >= dominant_role);
                    const re = {};
                    r.forEach(a => {
                        re[a] = roles[a];
                    });

                    obs.next(re);
                    obs.complete();
                });
            }
        });
    }

    canEditRole(role: string): Observable<boolean> {
        return Observable.create(obs => {
            forkJoin(this.user.pipe(filter(x => !!x), take(1)), this.constants.getConstSet('ROLE_')).subscribe(roles => {
                obs.next(this._getDominantRoleIndex(UserAuthService._user, roles) <= Object.keys(roles).indexOf(role));
                obs.complete();
            });
        });
    }

    private _getDominantRoleIndex(u: User, roles) {
        return u.roles.reduce((index, role) => {
            const i = Object.keys(roles).indexOf(role);
            return i < index ? i : index;
        }, 9999);
    }

    @GetterCache()
    get isPrimaryPhysician$(): Observable<boolean> {
        return this.physicianTeams$.pipe(map(teams => teams.some(pt => pt.user?.id === UserAuthService._user.id)));
    }

    @GetterCache()
    get physicianTeams$(): Observable<PhysicianTeam[]> {
        if (!UserAuthService._user || !['PERMISSION_READ_USER', 'PERMISSION_READ_PHYSICIAN_TEAMS'].some(x => UserAuthService._user.permissions.includes(x))) {
            return of([]);
        }
        return PhysicianTeam.list({is_member: 1}).pipe(
            map(teams => teams.sort((a, b) => a.facility.is_inactive === b.facility.is_inactive ?
                (a.user?.id || 0) - (b.user?.id || 0) :
                a.facility.is_inactive ? 1 : -1)),
            shareReplay(1),
        );
    }

    //TODO: subscribe to that fn not causing null issue in the subscriber?
    @GetterCache()
    get physicianTeamMemberships$(): Observable<PhysicianTeamMembershipSerializer[]> {
        if (!UserAuthService._user || !['PERMISSION_READ_USER', 'PERMISSION_READ_PHYSICIAN_TEAMS'].some(x => UserAuthService._user.permissions.includes(x))) {
            return of([]);
        }
        return this.physicianTeams$.pipe(
            map(teams => teams.filter(team => team.memberships.length).reduce((acc, team) => {
                const ownMemberships = team.memberships.filter(membership => membership.user.id === UserAuthService._user.id);
                return acc.concat(ownMemberships);
            }, []))
        );
    }

    @GetterCache()
    get hasBst$() {
        return this.physicianTeams$.pipe(map(teams => teams.some(t => t.customer)));
    }

    @GetterCache()
    get bstCustomers$() {
        return this.physicianTeams$.pipe(map(teams => new Set(teams.map(t => t.customer))));
    }

    @MethodCache()
    isInPhysicianTeam$(team: number | {id?: number}) {
        return this.physicianTeams$.pipe(map(teams => teams.some(t => t.id === getId(team))));
    }

    @MethodCache(physicianTeamsCacheIdSegmentsFn)
    getPhysicianTeams$(args: {physician_teams?: number[]; physician?; facility?}): Observable<PhysicianTeam[]> {
        if (args.physician_teams) return this.physicianTeams$.pipe(map(teams => teams?.filter(x => args.physician_teams.includes(x.id)) || []));

        const physicianId = getId(args.physician),
            facilityId = getId(args.facility);
        if (!physicianId || !facilityId) return of([]);

        return this.physicianTeams$.pipe(map(teams => teams.filter(t => getId(t.user) === physicianId && getId(t.facility) === facilityId) || []));
    }

    @MethodCache()
    retrievePhysicianTeam$(id: number) {
        return PhysicianTeam.retrieve(id).pipe(shareReplay(1));
    }

    @MethodCache(physicianTeamsCacheIdSegmentsFn)
    isResponsibleFor$(args: {physician_teams?: number[]; physician?; facility?}): Observable<boolean> {
        return this.getPhysicianTeams$(args).pipe(map(teams => {
            const uid = UserAuthService._user.id;
            return teams.some(t => t.user.id === uid || t.memberships.find(m => m.user.id === uid)?.care_team_lead);
        }));
    }

    @MethodCache()
    isPhysicianTeamMate$(userId: number) {
        if (userId === UserAuthService._user.id) {
            return of(true);
        }
        return this.physicianTeams$.pipe(map(teams => teams.some(team =>
            team.user?.id === userId || team.memberships.some(membership => membership.user.id === userId)
        )));
    }

    @MethodCache()
    canAdministerTask$(task: TaskActionSerializer): Observable<boolean> {
        if (!task.physician_team) return of(getId(task.user) === UserAuthService._user.id);

        const customerId = getId(UserAuthService._user.customer);
        if (customerId) {
            return this.retrievePhysicianTeam$(getId(task.physician_team)).pipe(
                catchError(() => of(null)),
                map((x: PhysicianTeam) => getId(x.customer) === customerId)
            );
        }

        return this.physicianTeams$.pipe(map(teams => teams.some(t => t.id === getId(task.physician_team))));
    }

    @MethodCache('reference')
    canEditTask$(task: TaskActionSerializer): Observable<boolean> {
        if (task.includes_e_prescription && ['SAVED', 'RX_FINALIZATION_PENDING', 'RX_FINALIZATION_ERROR'].includes(task.status)) {
            return of(false);
        }
        const isAuthor = getId(task.user) === UserAuthService._user.id;
        return task.physician_team ?
            isAuthor ?
                this.physicianTeams$.pipe(map(teams => teams.some(t => t.id === getId(task.physician_team)))) :
                this.isResponsibleFor$({physician_teams: [getId(task.physician_team)]}) :
            of(isAuthor);
    }

    @MethodCache()
    canComposeForPatient$(patient) {
        return this.user.pipe(
            take(1),
            switchMap(u => u.isSuperAdmin || getId(patient.customer) === getId(u.customer) ?
                of(true) :
                this.getPhysicianTeams$(patient).pipe(map(teams => teams.length > 0))
            )
        );
    }

    @MethodCache()
    canEditPatientClinical$(patient) {
        if (!UserAuthService._user?.permissions.includes('PERMISSION_WRITE_PATIENT_CLINICAL')) return of(false);

        return Patient.isBst(patient) ?
            this.canComposeForPatient$(patient) :
            of(false);
    }

    @MethodCache()
    canEditPatientAdmin$(patient) {
        if (!UserAuthService._user?.permissions.includes('PERMISSION_WRITE_PATIENT_ADMIN')) return of(false);

        return Patient.isBst(patient) ?
            this.canComposeForPatient$(patient) :
            of(false);
    }

    @MethodCache()
    canEditPatient$(patient: Patient | RelatedPatient) {
        if (['PERMISSION_WRITE_PATIENT_CLINICAL', 'PERMISSION_WRITE_PATIENT_ADMIN'].every(p => !UserAuthService._user?.permissions.includes(p))) {
            return of(false);
        }

        return Patient.isBst(patient) ?
            this.canComposeForPatient$(patient) :
            of(false);
    }

    canProposeVisitForAnyone(): boolean {
        return ['PERMISSION_WRITE_EPISODIC_PROPOSALS', 'PERMISSION_WRITE_FACE_TO_FACE_PROPOSALS']
            .some(x => UserAuthService._user?.permissions.includes(x));
    }

    clearCache() {
        AccessorUtils.clearCache(this);
        MethodUtils.clearCache(this);

        ModelBase.descendants
            .filter(x => x.clearCacheOnUserChange)
            .forEach(x => x.clear());

        this.twoFactorAuthService.passcode = null;
    }

    forgotPassword(email: string) {
        return this.api.ForgotPasswordView.post({email});
    }

    private _broadcastUser(authToken?, refreshToken?) {
        const {id, email, title_name, first_name, middle_name, last_name} = UserAuthService._user;
        const user: NewAuthUser = {id, email, title_name, first_name, middle_name, last_name};
        this.authBroadcastService.next({
            action: 'new_user',
            payload: {
                user,
                authToken,
                refreshToken,
            },
        });
    }
}
