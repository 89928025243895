import {assignOrCreate, ModelBase, ModelBaseAccessor, ModelBaseListAccessor} from './model-base';
import {
    APIService,
    AssessmentPlanReadSerializer,
    CachedOrderSimpleSerializer,
    CachedSchedulingReviewSerializer,
    ClinicalTestResultSerializer,
    CriticalVitalSerializer,
    DiagnosisActionReadSerializer,
    EPrescribingSerializer,
    EventActionReadSerializer,
    EventDetailSerializer,
    EventReviewReadSerializer,
    FamilyHistorySerializer,
    HistoryOfPresentIllnessReadSerializer,
    ImagingResultSerializer,
    InfectionCultureSerializer,
    InfectionEntryViewSetInfectionInformationSerializer,
    InfectionEntryViewSetSerializer,
    InfectionInformationSerializer,
    InfectionInformationVerificationSerializer,
    LabDetailedResultSerializer,
    LabOrderReadSerializer,
    LabReportDetailedSerializer,
    LabReportSerializer,
    LabResultBillingSerializer,
    LabResultDetailedSerializer,
    LabResultSerializer,
    LabResultTrendsSerializer,
    LabTestActionReadSerializer,
    LabTestDetailedSerializer,
    LabTestHistoricalDetailedSerializer,
    LabTestReviewReadSerializer,
    LabTestSerializer,
    LabTestSimpleSerializer,
    LocationWithUnitSerializer,
    OrderDetailSerializer,
    OrderReviewActionReadSerializer,
    OrderReviewReadSerializer,
    OtherOrderReadSerializer,
    PastMedicalHxReadSerializer,
    PatientOversightSerializer,
    PatientWithFacilitySerializer,
    PhysicianOrderActionReadSerializer,
    PhysicianOrderReadSerializer,
    PhysicianSerializer,
    ProblemListReadSerializer,
    ProcedureActionSerializer,
    ProcedureReadOnlySerializer,
    ReviewOfSystemsSerializer,
    RXOrderReadSerializer,
    TaskAssignedUserSimpleSerializer,
    TaskDownloadHistorySerializer,
    TaskSerializer,
    TasksForSchedulerSerializer,
    TaskSimpleSerializer,
    TaskStatusHistorySerializer,
    TaskValidatedBillingSerializer,
    TreatmentActionReadSerializer,
    TreatmentHistoryDetailedSerializer,
    TreatmentHistoryFlagSerializer,
    TreatmentReviewReadSerializer,
    ValidatedBillingTaskSerializer,
    VisitDetailsSerializer,
    VitalExaminationSerializer,
    VitalReviewActionReadSerializer,
    VitalReviewReadSerializer,
    ImmunizationReviewReadSerializer,
    ImmunizationReviewActionReadSerializer,
    AllergyPatientReadSerializer,
    PatientAllergyReactionReadSerializer,
    DemographicsSerializer,
    PatientInformationSerializer,
    BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer,
    BehavioralBaselineEvaluationSerializer,
    BehavioralFamilyHistorySerializer,
    BehavioralReviewOfSystemsSerializer,
    BehavioralDevelopmentalHistorySerializer,
    BehavioralPastPsychiatricHistorySerializer,
    PatientComplexitySerializer,
    BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer,
    FHIRQuestionnaireResponseSerializer,
    BehavioralSymptomsOfIllnessSerializer,
    BehavioralTraumaEvaluationSerializer,
    ProcedureActionReadSerializer,
    ProcedureReviewReadSerializer,
    ImagingResultReviewReadSerializer,
    ImagingResultReviewActionReadSerializer,
    ClinicalTestResultReviewActionReadSerializer,
    ClinicalTestResultReviewReadSerializer,
    ImmunizationSerializer,
    PatientSerializer,
    PatientAllergyReviewReadSerializer,
    PatientAllergyReviewActionReadSerializer,
    PatientAllergyReadSerializer,
    CodedNoteReadSerializer,
    RXOrderSerializer,
    ProcedureRelatedDeviceReadSerializer,
    BehavioralMentalStatusExamReadSerializer,
    BehavioralSocialHistoryReadSerializer,
    SocialHistoryReadSerializer,
    BehavioralPhysicalExaminationReadSerializer,
    PhysicalExaminationReadSerializer,
    ProcedureCreateUpdateSerializer,
    CCDAAuthorImportSerializer,
    PatientDocumentSerializer,
    PatientTaskProposalSerializer,
    ProgressNoteSerializer,
} from '../@core/api.service';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {Patient, RelatedPatient} from './patient';
import {User} from './user';
import {comparePeriod} from '../@theme/helpers';
import {getId, Modify} from '../utils/type.utils';
import {CheckPatientCache} from '../@core/utils/check-patient-cache';
import {ICDCode} from './ICD-code';
import {Human} from './human';
import {getConst} from '../@core/constants';
import {PhysicianTeam, PhysicianTeamUser} from './physician-team';
import {MedicalResultType} from './medical-result-type';
import {map} from 'rxjs/operators';
import {DrugName} from './drug-name';
import {Diagnosis} from './diagnosis';
import {limitText} from '../utils/string.utils';
import {toDateTime} from '../utils/date.utils';
import {GetterCache} from '../utils/accessor.utils';
import {Facility} from './facility';
import {SnomedConcept} from './snomed-concept';
import {DrugbankProduct, DrugbankProductConceptRxCui} from './drugbank-product';
import {CodedValue} from './coded-value';
import {RxNormCode} from './rx-norm-code';
import {ORDER_STATUS_CHOICES} from '../@core/order_status_choices';
import {TaskBillingStatusDetails} from '../portal/billing/visit-billing-per-day/visit-billing-day/task-billing-status';
import {VISIT_BILLING_CONSTANTS} from '../@core/visit_billing_constants';
import {FaxStatus} from './fax-status';

export class Reviewable extends ModelBase {
    related_tasks: Task[];
    reviews: Task[];

    get reviewed_at() {
        return this.reviews[0] ? this.reviews[0].created_at : null;
    }

    get reviewed_by() {
        return this.reviews[0] ? this.reviews[0].user : null;
    }

    get isReviewed() {
        return !!this.reviews[0];
    }

    assign(x, ...args) {
        const {related_tasks, ...y} = x;
        super.assign(y, ...args);

        if (related_tasks) {
            this.reviews = related_tasks.filter(x => x.review).map((t: any) => {
                const {review, ...out} = t;
                return out;
            });

            this.related_tasks = related_tasks.map(t => {
                const {review, ...out} = t;
                return out;
            });
        }

        this.setListAccessor('related_tasks', Task);
        this.setListAccessor('reviews', Task);
    }
}

export type FaxTemplate = 'facility_task' | 'task';

export class TaskStatusHistoryEntry extends ModelBase implements TaskStatusHistorySerializer {
    id: number;
    timestamp: Date | string;
    user: PhysicianSerializer;
    status: TaskStatus;
    fax: boolean;
    sms: boolean;
    fax_status: 'Add Failed' | 'Failed' | 'In Progress' | 'Sent';
    fax_error_code: string;
    fax_template: FaxTemplate;
    fax_response_timestamp: Date | string;
    fax_notification_sent: boolean;
    is_sms_sent: boolean;
    result_number: number;
    comment: string;

    faxStatus: {
        status: string;
        colorClass: string;
        colorOpacity: number;
        label: string;
        statusTooltip: string;
    };

    static viewSet = APIService.TaskStatusHistoryViewSet;

    assign(x) {
        super.assign(x);

        if (!this.faxStatus) {
            this.faxStatus = {
                status: null,
                colorClass: null,
                colorOpacity: null,
                label: null,
                statusTooltip: null,
            };
        }

        switch (this.fax_status) {
            case 'In Progress':
                Object.assign(this.faxStatus, {colorClass: null, colorOpacity: .33});
                break;
            case 'Sent':
                Object.assign(this.faxStatus, {colorClass: 'primary', colorOpacity: 1});
                break;
            case 'Failed':
                Object.assign(this.faxStatus, {colorClass: 'danger', colorOpacity: 1});
                break;
            case 'Add Failed':
                Object.assign(this.faxStatus, {colorClass: 'danger', colorOpacity: 1});
                break;
            default:
                Object.assign(this.faxStatus, {colorClass: null, colorOpacity: .33});
        }

        this.faxStatus.status = this.fax_status;
        const dateText = SimpleDatePipe.transform(this.fax_response_timestamp, false, true);
        this.faxStatus.label = this.fax_status ? `${this.fax_status} - ${dateText}` : null;
        this.faxStatus.statusTooltip = [this.fax_error_code, dateText].filter(x => x).join(' - ');
    }
}

export class ProblemList extends ModelBase implements ProblemListReadSerializer {
    id: number;
    actions: DiagnosisAction[];

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', DiagnosisAction);
    }
}

// export class LabTestReview extends ModelBase implements LabTestReviewReadSerializer {
export class LabTestReview extends ModelBase implements Modify<LabTestReviewReadSerializer, {actions: LabTestAction[]}> {
    id: number;
    actions: LabTestAction[];
    x_ray_review: string;
    comments: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', LabTestAction);
    }
}

// export class TreatmentReview extends ModelBase implements TreatmentReviewReadSerializer {
export class TreatmentReview extends ModelBase implements Modify<TreatmentReviewReadSerializer, {actions: TreatmentAction[]}> {
    id: number;
    actions: TreatmentAction[];
    medication_hx: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', TreatmentAction);
    }
}

export class EventReview extends ModelBase implements EventReviewReadSerializer {
    id: number;
    actions: EventAction[];
    review: string;
    wounds: string;
    diet: string;
    falls: string;
    infections: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', EventAction);
    }
}

export class VitalReview extends ModelBase implements VitalReviewReadSerializer {
    id: number;
    actions: VitalReviewAction[];

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', VitalReviewAction);
    }
}

export class OrderReview extends ModelBase implements OrderReviewReadSerializer {
    id: number;
    actions: OrderReviewAction[];

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', OrderReviewAction);
    }
}

export class ProcedureReview extends ModelBase implements ProcedureReviewReadSerializer {
    id: number;
    actions: ProcedureAction[];
    review: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', ProcedureAction);
    }
}

export class AllergyReview extends ModelBase implements PatientAllergyReviewReadSerializer {
    id: number;
    // @ts-ignore
    actions: AllergyAction[];
    review: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', AllergyAction);
    }
}

export class ImmunizationReview extends ModelBase implements ImmunizationReviewReadSerializer {
    id: number;
    actions: ImmunizationAction[];
    review: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', ImmunizationAction);
    }
}

export class ClinicalResultReview extends ModelBase implements ClinicalTestResultReviewReadSerializer {
    id: number;
    actions: ClinicalTestResultAction[];
    review: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', ClinicalTestResultAction);
    }
}

export class PastMedicalHx extends ModelBase implements PastMedicalHxReadSerializer {
    id: number;
    comments: string;
    primary_diagnosis: ICDCode;
    primary_diagnosis_comment: string;
    history_of_surgery_procedures: string;
    reason_for_admission: string;

    assign(x) {
        super.assign(x);
        this.setAccessor('primary_diagnosis', ICDCode);
    }
}

export class AssessmentPlan extends ModelBase implements AssessmentPlanReadSerializer {
    id: number;
    actions: DiagnosisAction[];
    assessment: string;
    plan: string;
    comments: string;
    related_icd_diagnoses: ICDCode[];
    systems: any;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', DiagnosisAction);
        this.setListAccessor('related_icd_diagnoses', ICDCode);
    }
}

export class PhysicianOrder extends ModelBase implements PhysicianOrderReadSerializer {
    id: number;
    actions: PhysicianOrderAction[];

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', PhysicianOrderAction);
    }
}

// export class LabTestAction extends ModelBase implements LabTestActionReadSerializer {
export class LabTestAction extends ModelBase implements Modify<LabTestActionReadSerializer, {lab_test: LabTest}> {
    id: number;
    lab_test: LabTest;
    action: string;
    comments: string;
    hash: string;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('lab_test', LabTest);
        this.setAccessor('rx_order', RXOrder);
    }
}

// export class TreatmentAction extends ModelBase implements TreatmentActionReadSerializer {
export class TreatmentAction extends ModelBase implements Modify<TreatmentActionReadSerializer, {treatment_history: TreatmentEntry}> {
    id: number;
    treatment_history: TreatmentEntry;
    action: string;
    icd: ICDCode;
    comments: string;
    hash: string;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('treatment_history', TreatmentEntry);
        this.setAccessor('icd', ICDCode);
        this.setAccessor('rx_order', RXOrder);
    }
}

// export class EventAction extends ModelBase implements EventActionReadSerializer {
export class EventAction extends ModelBase implements Modify<EventActionReadSerializer, {event: PatientEvent}> {
    id: number;
    event: PatientEvent;
    action: string;
    comments: string;
    hash: string;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('event', PatientEvent);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class VitalReviewAction extends ModelBase implements VitalReviewActionReadSerializer {
    id: number;
    vital: CriticalVital;
    action: string;
    comments: string;
    hash: string;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('vital', CriticalVital);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class OrderReviewAction extends ModelBase implements OrderReviewActionReadSerializer {
    id: number;
    order: Order;
    action: string;

    assign(x) {
        super.assign(x);
        this.setAccessor('order', Order);
    }
}

export class DiagnosisAction extends ModelBase implements DiagnosisActionReadSerializer {
    id: number;
    action: string;
    comments: string;
    icd: ICDCode;
    diagnosis: Diagnosis;
    hash: string;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('icd', ICDCode);
        this.setAccessor('diagnosis', Diagnosis);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class PhysicianOrderAction extends ModelBase implements PhysicianOrderActionReadSerializer {
    action: string;
    comments: string;
    hash: string;
    hidden_in_plan: boolean;
    icd: number;
    id: number;
    sort: number;
    physician_order: number;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class ProcedureAction extends ModelBase implements ProcedureActionReadSerializer {
    action: string;
    comments: string;
    hash: string;
    hidden_in_plan: boolean;
    id: number;
    procedure: ProcedureActionSerializer;
    lab_order?: LabOrderReadSerializer;
    rx_order?: RXOrderReadSerializer;
    other_order?: OtherOrderReadSerializer;

    assign(x) {
        super.assign(x);
        this.setAccessor('procedure', Procedure);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class AllergyAction extends ModelBase implements PatientAllergyReviewActionReadSerializer {
    action: string;
    comments: string;
    hash: string;
    hidden_in_plan: boolean;
    id: number;
    patient_allergy: Allergy;
    sort: number;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('patient_allergy', Allergy);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class ImmunizationAction extends ModelBase implements ImmunizationReviewActionReadSerializer {
    action: string;
    comments: string;
    hash: string;
    hidden_in_plan: boolean;
    id: number;
    immunization: Immunization;
    lab_order: LabOrderReadSerializer;
    rx_order: RXOrderReadSerializer;
    other_order: OtherOrderReadSerializer;

    assign(x) {
        super.assign(x);
        this.setAccessor('immunization', Immunization);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class ClinicalTestResultAction extends ModelBase implements ClinicalTestResultReviewActionReadSerializer {
    id: number;
    action?: string;
    clinical_test_result: ClinicalResult;
    comments?: string;
    hash?: string;
    hidden_in_plan?: boolean;
    sort: number;
    lab_order: LabOrderReadSerializer;
    rx_order?: RXOrder;
    other_order: OtherOrderReadSerializer;

    assign(x) {
        super.assign(x);
        this.setAccessor('clinical_test_result', ClinicalResult);
        this.setAccessor('rx_order', RXOrder);
    }
}

export type RxOrderStatus = 'ARSANA_PENDING' | 'PENDING' | 'XMIT' | 'XMIT_FAILED' | 'PRINTED' | 'CANCEL_PENDING' | 'CANCELLED';

export class RXOrder extends ModelBase implements RXOrderReadSerializer {
    id: number;
    @ModelBaseAccessor(DrugbankProduct) drugbank_product: DrugbankProduct;
    amount: string;
    controlled_substance: boolean;
    disp_amount: number;
    disp_units: string;
    dosage: string;
    dosage_uid: number;
    drug_code: string;
    duration: number;
    earliest_fill_date: Date | string;
    end: Date | string;
    freq: string;
    instructions: string;
    medication: string;
    notes: string;
    quantity: number;
    refills: number;
    @ModelBaseListAccessor(ICDCode) related_icd_diagnoses: ICDCode[];
    start: Date | string;
    substitution_allowed: boolean;
    route: string;
    e_prescribing_status: RxOrderStatus;

    @GetterCache()
    get colorClass() {
        if (!this.e_prescribing_status) return '';
        if (this.e_prescribing_status === 'XMIT') return 'success';
        if (['ARSANA_PENDING', 'PENDING'].includes(this.e_prescribing_status)) return 'warning';
        return 'danger';
    }

    getSerialized(obj: RXOrder = this): RXOrderSerializer {
        const {drugbank_product, ...rest} = obj;
        return {...super.getSerialized(rest), drugbank_product: getId(drugbank_product)};
    }
}

export class PatientEvent extends Reviewable implements EventDetailSerializer {
    id: number;
    date: Date | string;
    type: string;
    patient: PatientOversightSerializer;
    description: string;
    evaluation: string;
    display_name: any;

    static viewSet = APIService.EventViewSet;
    static patientDetailKey = 'events';

    static compareSort(a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    }
}

export class HasLabResults extends Reviewable {
    lab_results: LabResult[];

    assign(x) {
        if (x) {
            super.assign(x);

            this.setListAccessor('lab_results', LabResult);
        }
    }

    @GetterCache()
    get lab_result_types(): {name: string; lab_results?: (number | LabResult | LabResultDetailedSerializer)[]}[] {
        const a = [];
        this.lab_results.forEach(x => {
            if (x) {
                const type = a.find(y => y.name == x.culture_type);
                if (type) type.lab_results.push(x);
                else a.push({name: x.culture_type, lab_results: [x]});
            }
        });
        return a;
    }

    static isAbnormal(x): boolean {
        return x.is_abnormal ?? x.lab_results?.some(result => result && LabResult.isAbnormal(result)) ?? false;
    }

    @GetterCache()
    get isAbnormal(): boolean {
        return HasLabResults.isAbnormal(this);
    }

    static isCritical(x): boolean {
        return x.is_critical ?? x.lab_results?.some(result => result && LabResult.isCritical(result)) ?? false;
    }

    @GetterCache()
    get isCritical(): boolean {
        return HasLabResults.isCritical(this);
    }

    static isInconclusive(x): boolean {
        return x.is_inconclusive ?? x.lab_results?.some(result => result && LabResult.isInconclusive(result)) ?? false;
    }

    @GetterCache()
    get isInconclusive(): boolean {
        return HasLabResults.isInconclusive(this);
    }

    static isNoResult(x): boolean {
        return x.is_no_result ?? x.lab_results?.some(result => result && LabResult.isNoResult(result)) ?? false;
    }

    @GetterCache()
    get isNoResult(): boolean {
        return HasLabResults.isNoResult(this);
    }

    static getColorClass(x): string {
        if (HasLabResults.isCritical(x)) return 'danger';
        if (HasLabResults.isAbnormal(x)) return 'warning';
        if (HasLabResults.isInconclusive(x) || HasLabResults.isNoResult(x)) return 'secondary';
        return '';
    }

    @GetterCache()
    get colorClass(): string {
        return HasLabResults.getColorClass(this);
    }
}

export class LabReport extends HasLabResults implements LabReportDetailedSerializer {
    id?: number;
    created_at?: Date;
    modified_at?: Date;
    order_id?: string;
    status?: string;
    // @ts-ignore
    lab_tests: LabTest[];
    // @ts-ignore
    patient: Patient;
    physician?: User;
    collected_date?: Date;
    resulted_date?: Date;
    ordered_date?: Date;
    description?: string;
    has_inconclusive_result: any;
    has_no_result: any;
    lab_order: number;
    origin: string;
    has_corrected_result?: boolean;

    @ModelBaseAccessor(FaxStatus) fax_status: FaxStatus;

    @GetterCache()
    get labTypes() {
        if (!this.lab_tests) return;
        return [...new Set(this.lab_tests.map(x => x.lab_type))];
    }

    static viewSet = APIService.LabReportViewSet;

    assign(labReport?: LabReportSerializer | LabReportDetailedSerializer | LabReport) {
        if (labReport) {
            const {patient, physician, ...data} = labReport;
            super.assign(data);

            if (patient && typeof patient !== 'number') this.patient = patient instanceof Patient ? patient : new Patient(patient);
            if (!this.patient) this.patient = patient as any;
            if (physician && typeof physician !== 'number') this.physician = physician instanceof User ? physician : new User(physician);
            if (!this.physician) this.physician = physician as any;

            this.setListAccessor('lab_tests', LabTest);
        }
        if (this.collected_date) this.ordered_date = this.collected_date;
    }
}

export class LabTest extends HasLabResults implements Modify<LabTestSerializer | LabTestDetailedSerializer | LabTestHistoricalDetailedSerializer, {lab_report: LabReport; lab_results: LabResult[]}> {
    lab_report: LabReport;
    id: number;
    created_at: Date;
    modified_at: Date;
    name: string;
    lab_type: string;
    infection_information: number;
    source: string;
    description: string;
    collected_date: Date | string;
    resulted_date: Date | string;
    lab_test_histories: ({isOld?: boolean} & LabTestDetailedSerializer)[];
    has_no_result: boolean;
    has_inconclusive_result: boolean;
    status: string;
    loinc_code?: string;

    static viewSet = APIService.LabTestViewSet;
    static patientDetailKey = 'labTests';

    assign(labTest?: LabTestSerializer | LabTestDetailedSerializer | LabTestHistoricalDetailedSerializer | LabTest) {
        if (labTest) {
            super.assign(labTest);

            this.setAccessor('lab_report', LabReport);

            const date = (this.resulted_date ? new Date(this.resulted_date) : new Date()).getTime();
            this.lab_test_histories?.forEach(x => {
                x.isOld = date - new Date(x.resulted_date).getTime() > 1 * 30 * 24 * 60 * 60 * 1000;
            });
        }
    }

    private _infectionCultures;

    @GetterCache()
    get infectionCultures() {
        if (!this._infectionCultures) {
            this._infectionCultures = this.lab_results?.length ?
                this.lab_results.reduce((acc, lr) => lr?.infection_culture ?
                    acc.concat([{isAbnormal: lr.isAbnormal, isCritical: lr.isCritical, ...(lr.infection_culture as InfectionCultureSerializer)}]) :
                    acc, []) :
                [];
        }
        return this._infectionCultures;
    }

    static toString(labTest: LabTestSerializer | LabTestDetailedSerializer | LabTest): string {
        return `${SimpleDatePipe.transform(labTest.created_at)} ${labTest.lab_type} ${labTest.name}`;
    }

    toString(): string {
        return LabTest.toString(this);
    }

    get patient(): Patient {
        return this.lab_report?.patient;
    }

    set patient(x) {
        if (this.lab_report) this.lab_report.patient = x;
    }

    get collectedDate(): string | Date {
        return this.collected_date || this.lab_report?.collected_date;
    }

    get resultedDate(): string | Date {
        return this.resulted_date || this.lab_report?.resulted_date;
    }

    getSerialized(): any {
        const d: any = {...this};
        if (d.lab_report) {
            if (d.lab_report.id < 0 || d.lab_report.__newEntry) {
                const {lab_test, ...reportData} = d.lab_report;
                reportData.patient = getId(reportData.patient || this.patient);
                if (!reportData.status) reportData.status = this.status;
                if (!reportData.collected_date) reportData.collected_date = this.collected_date;
                d.lab_report = d.lab_report.getSerialized(reportData);
            } else {
                d.lab_report = getId(d.lab_report);
            }
        }
        return super.getSerialized(d);
    }
}

export class LabResult extends ModelBase implements LabResultSerializer, LabResultDetailedSerializer, LabResultBillingSerializer, LabResultTrendsSerializer {
    id: number;
    created_at: Date;
    modified_at: Date;
    // @ts-ignore
    lab_report: number | LabReport;
    culture_type: string;
    quantity: string;
    unit: string;
    ref_range: string;
    // @ts-ignore
    infection_culture: number | InfectionCultureSerializer;
    lab_detailed_results: LabDetailedResult[];
    description: string;
    lab_attachments: number[];
    flag: string;
    is_critical: boolean;
    is_abnormal: boolean;
    is_inconclusive: boolean;
    is_no_result: boolean;
    lab_test: LabTestSimpleSerializer;
    mdro: string;

    assign(labResult?: LabResultDetailedSerializer | LabResult) {
        if (labResult) {
            super.assign(labResult);

            this.setListAccessor('lab_detailed_results', LabDetailedResult);
        }
    }

    static isAbnormal(x: LabResultDetailedSerializer | LabResult): boolean {
        return x.is_abnormal;
    }

    get isAbnormal(): boolean {
        return LabResult.isAbnormal(this);
    }

    static isCritical(x: LabResultDetailedSerializer | LabResult): boolean {
        return x.is_critical;
    }

    get isCritical(): boolean {
        return LabResult.isCritical(this);
    }

    static isInconclusive(x): boolean {
        return x.is_inconclusive;
    }

    get isInconclusive(): boolean {
        return LabResult.isInconclusive(this);
    }

    static isNoResult(x): boolean {
        return x.is_no_result;
    }

    get isNoResult(): boolean {
        return LabResult.isNoResult(this);
    }

    static getColorClass(x: LabResultDetailedSerializer | LabResult | LabResultTrendsSerializer): string {
        if (LabResult.isCritical(x)) return 'danger';
        if (LabResult.isAbnormal(x)) return 'warning';
        if (LabResult.isInconclusive(x) || LabResult.isNoResult(x)) return 'secondary';
        return '';
    }

    @GetterCache()
    get colorClass() {
        return LabResult.getColorClass(this);
    }
}

export class LabDetailedResult extends ModelBase implements LabDetailedResultSerializer {
    id?: number;
    susceptibility?: string;
    interpretation?: string;
    mic?: string;

    get colorClass(): string {
        switch (this.interpretation) {
            case 'RESISTANT':
                return 'danger';
            case 'INTERMEDIATE':
                return 'warning';
            case 'SENSITIVE':
                return 'success';
            default:
                return '';
        }
    }
}

export class Procedure extends Reviewable implements ProcedureReadOnlySerializer {
    id: number;
    patient: number;
    performed: Date | string;
    procedure_related_devices: ProcedureRelatedDeviceReadSerializer[];
    status: string;
    @ModelBaseAccessor(SnomedConcept) code: SnomedConcept;
    @ModelBaseListAccessor(SnomedConcept) target_sites: SnomedConcept[];
    @ModelBaseAccessor(ICDCode) related_icd_diagnosis: ICDCode;

    static viewSet = APIService.ProcedureViewSet;

    @GetterCache()
    get statusColorClass(): string {
        switch (this.status) {
            case 'STATUS_COMPLETED':
                return 'success';
            default:
                return 'warning';
        }
    }

    getSerialized(obj: Procedure = this): ProcedureCreateUpdateSerializer {
        const {code, target_sites, related_icd_diagnosis, ...rest} = obj;
        return {...super.getSerialized(rest),
            code: getId(code),
            target_sites: target_sites.map(x => getId(x)),
            related_icd_diagnosis: getId(related_icd_diagnosis)};
    }
}

export class AllergyReaction extends ModelBase implements PatientAllergyReactionReadSerializer {
    date: Date | string;
    id: number;
    note: string;
    severity: string;
    @ModelBaseAccessor(SnomedConcept) snomed_concept: SnomedConcept;

    @GetterCache()
    get severityColorClass(): string {
        switch (this.severity) {
            case 'severe':
                return 'danger';
            case 'moderate':
                return 'warning';
            default:
                return 'success';
        }
    }

    getSerialized(obj: AllergyReaction = this): any {
        const {snomed_concept, ...rest} = obj;
        return {...super.getSerialized(rest), snomed_concept: getId(snomed_concept)};
    }
}

export class Allergy extends Reviewable implements PatientAllergyReadSerializer, AllergyPatientReadSerializer {
    id: number;
    patient: number;
    reaction_note: string;
    severity: string;
    start_date: Date | string;
    end_date: Date | string;
    is_active: boolean;
    verification_status: string;
    drugbank_product_concept: number; // rxcui is used instead
    status: string;
    sub_type: string;
    type: string;
    patient_document: number;
    allergy: any; // AllergyReadSerializer
    // CCD Reconciliation
    author?: CCDAAuthorImportSerializer[];
    @ModelBaseAccessor(SnomedConcept) snomed_concept: SnomedConcept;
    @ModelBaseAccessor(DrugbankProductConceptRxCui) drugbank_product_concept_rxcui: DrugbankProductConceptRxCui;
    @ModelBaseAccessor(SnomedConcept) allergy_type_snomed_concept?: SnomedConcept;
    @ModelBaseListAccessor(AllergyReaction) reactions: AllergyReaction[];

    static viewSet = APIService.PatientAllergyViewSet;
    static patientDetailKey = 'allergies';

    assign(x, ...args) {
        const {allergy, ...rest} = x;
        super.assign(rest, ...args);
        if (allergy && typeof allergy !== 'number') this.allergy = allergy;
    }

    @GetterCache()
    get allergyText(): string {
        return this.allergy?.name || this.snomed_concept?.name || this.drugbank_product_concept_rxcui?.name;
    }

    @GetterCache()
    get statusColorClass(): string {
        if (!this.verification_status) return '';
        return ['unconfirmed', 'presumed'].includes(this.verification_status) ? 'warning' : ['refuted', 'entered-in-error'].includes(this.verification_status) ? 'danger' : 'success';
    }

    @GetterCache()
    get reactionColorClass(): string {
        return this.reactions?.some(x => x.severityColorClass === 'danger') ? 'danger' : this.reactions?.some(x => x.severityColorClass === 'warning') ? 'warning' : 'success';
    }

    @GetterCache()
    get reactionSeverity(): string {
        return this.reactions?.some(x => x.severity === 'severe') ? 'severe' : this.reactions?.some(x => x.severity === 'moderate') ? 'moderate' : this.reactions?.some(x => x.severity === 'mild') ? 'mild' : '';
    }

    @GetterCache()
    get isActive(): boolean {
        return this.is_active && !['refuted', 'entered-in-error'].includes(this.verification_status);
    }

    getSerialized(obj: Allergy = this): any {
        const {drugbank_product_concept, drugbank_product_concept_rxcui, snomed_concept, allergy_type_snomed_concept, ...rest} = obj;
        return {
            ...super.getSerialized(rest),
            allergy_type_snomed_concept: getId(allergy_type_snomed_concept),
            snomed_concept: getId(snomed_concept),
            drugbank_product_concept: getId(drugbank_product_concept),
            drugbank_product_concept_rxcui: getId(drugbank_product_concept_rxcui),
        };
    }
}

export class ImagingResult extends Reviewable implements ImagingResultSerializer {
    id: number;
    patient: number;
    code: string;
    code_display: string;
    date: Date | string;
    order: OtherOrderReadSerializer;
    status: string;
    unit: string;
    value_code: string;
    value_code_display: string;
    value_quantity: string;
    value_string: string;

    static viewSet = APIService.ImagingResultViewSet;

    assign(x) {
        super.assign(x);
        this.setAccessor('icd', ICDCode);
    }

    @GetterCache()
    get statusColorClass(): string {
        switch (this.status) {
            case 'held':
                return 'warning';
            case 'completed':
            case 'active':
            case 'normal':
            case 'new':
                return 'success';
            case 'aborted':
            case 'cancelled':
            case 'nullified':
            case 'obsolete':
            case 'suspended':
                return 'danger';
            default:
                return null;
        }
    }

    @GetterCache()
    get isRelevant(): boolean {
        return this.statusColorClass !== 'danger';
    }

    getSerialized(obj: ImagingResult = this): any {
        return {
            ...super.getSerialized(obj),

        };
    }
}

export class ImagingResultReview extends ModelBase implements ImagingResultReviewReadSerializer {
    id: number;
    actions: ImagingResultAction[];
    review: string;

    assign(x) {
        super.assign(x);
        this.setListAccessor('actions', ImagingResultAction);
    }
}

export class ImagingResultAction extends ModelBase implements ImagingResultReviewActionReadSerializer {
    action: string;
    comments: string;
    hash: string;
    hidden_in_plan: boolean;
    id: number;
    imaging_result: ImagingResult;
    sort: number;
    lab_order: LabOrderReadSerializer;
    other_order: OtherOrderReadSerializer;
    rx_order: RXOrder;

    assign(x) {
        super.assign(x);
        this.setAccessor('imaging_result', ImagingResult);
        this.setAccessor('rx_order', RXOrder);
    }
}

export class Immunization extends Reviewable implements ImmunizationSerializer {
    id?: number;
    name?: string;
    date?: Date | string;
    expiration?: Date | string;
    step?: string;
    type?: string;
    description?: string;
    result?: string;
    staff_name?: string;
    consent_status?: string;
    site?: string;
    lot_number?: string;
    facility?: number;
    is_covid_related?: boolean;
    procedure_type?: string;
    code?: number;
    status?: string;
    status_reason?: number;
    patient: PatientSerializer;

    assign(x) {
        const updateName = typeof x.code === 'number' && (!x.name || (x.name && this.code && this.code !== x.code));

        super.assign(x);

        if (updateName) this.name = CodedValue.get(this.code)?.name;
    }

    static viewSet = APIService.ImmunizationViewSet;
    static patientDetailKey = 'immunizations';

    @GetterCache()
    get isRelevant(): boolean {
        return this.statusColorClass !== 'danger';
    }

    @GetterCache()
    get statusColorClass(): string {
        switch (this.status) {
            case 'held':
                return 'warning';
            case 'completed':
            case 'active':
            case 'normal':
            case 'new':
                return 'success';
            case 'aborted':
            case 'cancelled':
            case 'nullified':
            case 'obsolete':
            case 'suspended':
                return 'danger';
            default:
                return null;
        }
    }

    getSerialized(obj: Immunization = this): any {
        return {
            ...super.getSerialized(obj),
            patient: getId(obj.patient),
            // status: getId(obj.status),
            vaccine: getId(obj.code),
            status_reason: getId(obj.status_reason),
        };
    }
}

export class ClinicalResult extends Reviewable implements ClinicalTestResultSerializer {
    id?: number;
    status?: string;
    code?: string;
    code_display?: string;
    patient?: number;
    date?: Date | string;
    value_code?: string;
    value_code_display?: string;
    value_string?: string;
    value_quantity?: string;
    unit?: string;

    static viewSet = APIService.ClinicalTestResultViewSet;

    @GetterCache()
    get statusColorClass(): string {
        switch (this.status) {
            case 'held':
                return 'warning';
            case 'completed':
            case 'active':
            case 'normal':
            case 'new':
                return 'success';
            case 'aborted':
            case 'cancelled':
            case 'nullified':
            case 'obsolete':
            case 'suspended':
                return 'danger';
            default:
                return null;
        }
    }

    @GetterCache()
    get isRelevant(): boolean {
        return this.statusColorClass !== 'danger';
    }
}

export class InfectionEntry implements InfectionEntryViewSetSerializer {
    id?: number;
    created_at: Date | string;
    // @ts-ignore
    infection_information?: InfectionInformation[];
    // @ts-ignore
    location?: number | LocationWithUnitSerializer;
    // @ts-ignore
    patient?: number | Patient;
    start_date?: Date;
    end_date?: Date;

    constructor(ie?: InfectionEntryViewSetSerializer | InfectionEntry) {
        if (ie) {
            if (ie.patient && typeof ie.patient !== 'number') ie.patient = new Patient(ie.patient);
            if (ie.infection_information && ie.infection_information.length) {
                ie.infection_information = (ie.infection_information as (InfectionInformationSerializer | InfectionEntryViewSetInfectionInformationSerializer | InfectionInformation)[])
                    .map(x => new InfectionInformation(x))
                    .sort((a, b) => (comparePeriod(a, b, 'symptom_onset_date', 'resolved')));
            }
            Object.assign(this, ie);

            const pid = getId(this.patient);
            if (pid && ie.infection_information && ie.infection_information.length) CheckPatientCache.add(pid, 'infectionEntries', this.id);
        }
    }

    get hasConfirmedInfection(): boolean {
        return this.infection_information?.some(x => x.isConfirmed);
    }

    @GetterCache()
    get modifiedAt() {
        return this.infection_information?.map(x => x.modified_at || x.created_at).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0] || this.created_at;
    }
}

export class InfectionInformation implements InfectionEntryViewSetInfectionInformationSerializer, InfectionInformationSerializer {
    id?: number;
    created_at: Date | string;
    modified_at: Date | string;
    modified_by: number;
    // @ts-ignore
    infection?: number | InfectionCultureOfEventsSerializer;
    type_of_isolation?: string;
    // @ts-ignore
    infection_entries?: (number | InfectionEntry)[];
    symptom_onset_date?: Date;
    type_of_acquisition?: string;
    date_of_order?: Date;
    date_of_result?: Date;
    confirmed?: boolean;
    resolved?: Date;
    icd?: ICDCode;
    // @ts-ignore
    treatment_history?: (TreatmentEntry | null)[];
    // @ts-ignore
    lab_tests?: LabTest[];
    patient?: number | Patient;
    infection_information_verifications: InfectionInformationVerificationSerializer[];
    recurrence_days: number;

    constructor(ii?: InfectionInformationSerializer | InfectionEntryViewSetInfectionInformationSerializer | InfectionInformation) {
        if (ii) {
            if (ii.lab_tests && ii.lab_tests.length) ii.lab_tests = (ii.lab_tests as (LabTestSerializer | LabTestDetailedSerializer | LabTest)[]).map(x => assignOrCreate(LabTest, x));
            if (ii.icd && !(ii.icd instanceof ICDCode)) ii.icd = assignOrCreate(ICDCode, ii.icd);
            if (ii.treatment_history && ii.treatment_history.length) {
                ii.treatment_history = (ii.treatment_history as any[])
                    .map(x => x instanceof TreatmentEntry ? x : assignOrCreate(TreatmentEntry, x))
                    .sort((a, b) => comparePeriod(a, b, 'start_date', 'end_date'));
            } else {
                ii.treatment_history = [null];
            }
            Object.assign(this, ii);
        }
    }

    static isConfirmed(x: InfectionInformationSerializer | InfectionEntryViewSetInfectionInformationSerializer | InfectionInformation): boolean {
        if (x.lab_tests?.length) return (x.lab_tests as (LabTestSerializer | LabTestDetailedSerializer | LabTest)[]).some(test => LabTest.isAbnormal(test as HasLabResults));
    }

    get isVerified(): boolean {
        return this.infection_information_verifications?.length &&
            this.infection_information_verifications.every(verification => verification.verified);
    }

    get verifiedText(): string {
        if (!this.isVerified && !this.infection_information_verifications.length) return null;

        const byTxt = `${Human.getName(this.infection_information_verifications[0].created_by)} @ ${toDateTime(this.infection_information_verifications[0].created_at).toFormat('MM/dd/yyyy')}`;

        if (!this.isVerified) return `Flagged as invalid based on McGeer criteria by ${byTxt}`;

        const labNotAvailableText = this.labNotAvailable ? 'Incomplete, lab unavailable. ' : '';

        if (this.isVerified) return `${labNotAvailableText}Verfied based on McGeer criteria by ${byTxt}`;
    }

    get labNotAvailable(): boolean {
        return this.infection_information_verifications &&
            this.infection_information_verifications.length &&
            this.infection_information_verifications.some(verification =>
                verification.dynamic_form_values &&
                verification.dynamic_form_values.labNotAvailable);
    }

    get verificationStatus(): 'Y' | 'N' | 'I' | null {
        switch (this.isVerified) {
            case false:
                return 'N';
            case true:
                return this.labNotAvailable ? 'I' : 'Y';
            default:
                return null;
        }
    }

    get isConfirmed(): boolean {
        return InfectionInformation.isConfirmed(this);
    }

    get acquisitionText(): string {
        if (!this.type_of_acquisition) return '';
        return getConst(this.type_of_acquisition);
    }

    get isCompleted(): boolean {
        if (!this.treatment_history) return false;
        for (const te of this.treatment_history) {
            if (!te || !te.end_date || new Date(te.end_date) >= new Date()) return false;
        }
        return true;
    }

    get isResolved() {
        return this.isCompleted;
    }
}

const TREATMENT_ORDER_STATUS_ICONS: {[key: string]: string} = {
    'RF': 'repeat',
    'OF': 'repeat',
    'FU': 'repeat',
    'AF': 'repeat',
    'DC': 'stop-circle',
    'DR': 'stop-circle',
    'OD': 'stop-circle',
    'CA': 'ban',
    'CP': 'ban',
    'CR': 'ban',
    'OC': 'ban',
    'HD': 'pause',
    'HR': 'pause',
    'OH': 'pause',
    'NW': 'plus',
    'PR': 'plus',
    'UA': 'question',
    'UC': 'question',
    'UD': 'question',
    'UF': 'question',
    'UH': 'question',
    'UM': 'question',
    'UN': 'question',
    'UR': 'question',
    'UX': 'question',
};

export class TreatmentEntry extends Reviewable implements TreatmentHistoryDetailedSerializer {
    id?: number;
    drugbank_product: DrugbankProduct;
    drugbank_product_concept_rx_cui: DrugbankProductConceptRxCui; // TODO: remove if unnecessary
    rx_norm_code: RxNormCode;
    // @ts-ignore
    infection_information?: number | InfectionInformation;
    amount?: string;
    start_date?: string | Date;
    end_date?: string | Date;
    drug_name?: DrugName;
    physician?: PhysicianTeamUser;
    physician_name: string;
    // @ts-ignore
    patient?: RelatedPatient;
    drug: string;
    icd: ICDCode;
    icd_id: number; // handled by ModelBase
    prn: string;
    instructions: string;
    indications_for_use: string;
    flags?: TreatmentHistoryFlagSerializer[];
    admit_census: any;
    discharge_census: any;
    modified_by: number;
    product_type: string;
    product_type_category: any;
    calculated_end_date: Date | string;
    parent: number;
    patient_document: number;
    // TreatmentHistoryCreateSerializer
    dose_quantity: number;
    dose_unit: string;
    freq: string;
    frequency: number;
    frequency_event: string;
    frequency_unit: string;
    route_code: string;
    route_code_system: string;
    order_status?: string;
    // CCD Reconciliation
    author?: CCDAAuthorImportSerializer[];

    static viewSet = APIService.TreatmentHistoryViewSet;
    static patientDetailKey = 'treatmentHistory';

    assign(t?: TreatmentHistoryDetailedSerializer | TreatmentEntry) {
        if (t) {
            if (t.infection_information && typeof t.infection_information !== 'number' && !(t.infection_information instanceof InfectionInformation)) {
                t.infection_information = new InfectionInformation(t.infection_information);
            }

            super.assign(t);

            this.setAccessor('drugbank_product', DrugbankProduct);
            this.setAccessor('drugbank_product_concept_rx_cui', DrugbankProductConceptRxCui);
            this.setAccessor('rx_norm_code', RxNormCode);
            this.setAccessor('icd', ICDCode);
            this.setAccessor('physician', PhysicianTeamUser);
            this.setAccessor('patient', RelatedPatient);
            this.setAccessor('drug_name', DrugName);
        }
    }

    static isConsolidatedRecord(treatmentEntry: TreatmentHistoryDetailedSerializer | TreatmentEntry) {
        return treatmentEntry.parent === null;
    }

    static drugCategories(treatmentEntry: TreatmentHistoryDetailedSerializer | TreatmentEntry): string[] {
        if (treatmentEntry.drug_name?.drug?.length) {
            return treatmentEntry.drug_name.drug.reduce((cats, drug) => {
                if (drug.categories?.length) {
                    drug.categories.forEach(c => {
                        if (!cats.some(x => x == c.name)) cats.push(c.name);
                    });
                }
                return cats;
            }, []);
        }
        return undefined;
    }

    static amountText(x): string {
        return [x.amount, x.product_type].filter(y => y).join(' ');
    }

    static instructionsCombined(x): string {
        let out = [x.amount, x.product_type].filter(y => y).join(' ');
        if (x.instructions) out = [out, limitText(x.instructions, 100)].filter(y => y).join('; ');
        return out;
    }

    static drugText(x): string {
        return x.drugbank_product?.legacy_name ||
               (x.drugbank_product?.name && x.drugbank_product?.description ?
                   `${x.drugbank_product?.name} - ${x.drugbank_product?.description}` :
                   (x.drugbank_product?.prescribable_name ||
               x.drugbank_product_concept_rx_cui?.name ||
               x.rx_norm_code?.term ||
               x.drug_name?.name ||
               x.drug ||
               ''));
    }

    static getResistance(x): TreatmentHistoryFlagSerializer {
        return x.flags && x.flags.find(flag => flag.type === 'RESISTANCE') || null;
    }

    static isResolved(x): boolean {
        return x.end_date && new Date(x.end_date) < new Date();
    }

    static isConsolidatedResolved(x): boolean {
        return x.calculated_end_date && new Date(x.calculated_end_date) < new Date();
    }

    static getClass(x): string {
        if (TreatmentEntry.isResolved(x)) return 'completed';
        if (x.reviewed_by) return 'text-muted';
        return null;
    }

    static getOrderStatus(x): string {
        return (x.order_status && x.order_status in ORDER_STATUS_CHOICES) ? ORDER_STATUS_CHOICES[x.order_status] : null;
    }

    static getOrderStatusIcon(x): string | null{
        if (!(x.order_status in ORDER_STATUS_CHOICES)) return null;

        return TREATMENT_ORDER_STATUS_ICONS[x.order_status] || null;
    }

    get isConsolidatedRecord() {
        return TreatmentEntry.isConsolidatedRecord(this);
    }

    @GetterCache()
    get amountText(): string {
        return TreatmentEntry.amountText(this);
    }

    @GetterCache()
    get instructionsCombined(): string {
        return TreatmentEntry.instructionsCombined(this);
    }

    @GetterCache()
    get drugCategories(): string[] {
        return TreatmentEntry.drugCategories(this);
    }

    @GetterCache()
    get drugText(): string {
        return TreatmentEntry.drugText(this);
    }

    @GetterCache()
    get resistance(): TreatmentHistoryFlagSerializer {
        return TreatmentEntry.getResistance(this);
    }

    @GetterCache()
    get isResolved(): boolean {
        return TreatmentEntry.isResolved(this);
    }

    get isActive() {
        return !this.isResolved;
    }

    @GetterCache()
    get isConsolidatedResolved(): boolean {
        return TreatmentEntry.isConsolidatedResolved(this);
    }

    @GetterCache()
    get class(): string {
        return TreatmentEntry.getClass(this);
    }

    @GetterCache()
    get orderStatus(): string {
        return TreatmentEntry.getOrderStatus(this);
    }

    get orderStatusIcon(): string | null {
        return TreatmentEntry.getOrderStatusIcon(this);
    }

    getSerialized(obj: TreatmentEntry = this) {
        const {
            patient,
            physician,
            drug_name,
            drugbank_product,
            drugbank_product_concept_rx_cui,
            rx_norm_code,
            icd,
            infection_information,
            ...rest
        } = obj;
        return {
            ...super.getSerialized(rest),
            patient: getId(patient),
            physician: getId(physician),
            drug_name: getId(drug_name),
            drugbank_product: getId(drugbank_product),
            drugbank_product_concept_rxcui: getId(drugbank_product_concept_rx_cui),
            rx_norm_code: getId(rx_norm_code),
            icd: getId(icd),
            __drugText: this.drugText,
        };
    }
}

export class CriticalVital extends Reviewable implements CriticalVitalSerializer {
    id: number;
    recorded: Date | string;
    medical_result_type: number;
    value_before: number;
    value: number;
    days_without_bowel_movement: number;
    bmi: any;
    type: MedicalResultType;
    vital_type: any;
    patient_id: number;
    value_secondary: any;

    isWeight: boolean;

    // only for weight type CriticalVital
    weight_before: number;
    weight_recent: number;
    height: number;
    change: number;
    changePct: number;

    static viewSet = APIService.VitalViewSet;
    static patientDetailKey = ['criticalVitals', 'vitals', 'vitals7', 'vitals30', 'vitals90', 'vitals180'];

    assign(x: CriticalVital | CriticalVitalSerializer, types?) {
        const weightType = types && types.find(type => type.name === 'Weight');

        this.type = getId(x.medical_result_type) as any;

        this.setAccessor('type', MedicalResultType);

        if (this.isWeight || weightType && (weightType.id == x.medical_result_type || weightType.id == (x.medical_result_type as any).id)) {
            super.assign({
                isWeight: true,
                id: x.id,
                recorded: x.recorded,
                medical_result_type: getId(x.medical_result_type),
                weight_before: x.value_before || (x as CriticalVital).weight_before,
                weight_recent: x.value || (x as CriticalVital).weight_recent,
                change: ((x as CriticalVital).weight_recent ?
                    (x as CriticalVital).weight_recent - (x as CriticalVital).weight_before :
                    x.value - x.value_before),
                changePct: ((x as CriticalVital).change ?
                    (x as CriticalVital).change / (x as CriticalVital).weight_before :
                    (x.value - x.value_before) / x.value_before) * 100,
                bmi: x.bmi,
                height: x.bmi ? Math.round(Math.sqrt(x.value / x.bmi * 703)) : null,
                vital_type: x.vital_type,
                related_tasks: x.related_tasks,
                patient_id: (x as any).patient_id,
            });
        } else {
            super.assign(x);
        }
    }
}

export class Order extends Reviewable implements OrderDetailSerializer, CachedOrderSimpleSerializer {
    description: string;
    end_date: Date | string;
    flow_sheet_type: string;
    id: number;
    order_type: string;
    patient: PatientOversightSerializer;
    physician: User;
    start_date: Date | string;
}

export function compareInfectionInformationFn(a, b): number {
    return new Date(b.symptom_onset_date).getTime() - new Date(a.symptom_onset_date).getTime() ||
        (b.resolved ? new Date(b.resolved) : new Date()).getTime() - (a.resolved ? new Date(a.resolved) : new Date()).getTime();
}

export const criticalValueViewTransformer = map(([types, criticalList]) => {
    const newList = criticalList.map(patient => transformCriticalValue(types, patient));
    return [types, newList];
});

export function transformCriticalValue(types, patient) {
    return {
        ...patient,
        risks: patient.risks.map(risk => {
            risk.patient_id = patient.id;
            return assignOrCreate(CriticalVital, risk, types);
        }),
    };
}

export type TaskStatus = 'DRAFT' | 'SAVED' | 'RX_FINALIZATION_STARTED' | 'RX_FINALIZATION_PENDING' | 'RX_FINALIZATION_ERROR';

export class ProgressNote extends ModelBase implements ProgressNoteSerializer {
    id?: number;
    date?: Date | string;
    category?: string;
    patient?: number;
    description?: string;
    modified_by?: string;
}

// export class Task extends ModelBase implements TaskSerializer, ValidatedTaskSerializer {
// export class Task extends ModelBase implements Modify<TaskSerializer | TaskSimpleSerializer | ValidatedTaskSerializer, {treatment_review: TreatmentReview; lab_test_review: LabTestReview}> {
export class Task extends ModelBase implements TaskSerializer, TaskSimpleSerializer, ValidatedBillingTaskSerializer, TasksForSchedulerSerializer, TaskValidatedBillingSerializer {
    id: number;
    user: PhysicianSerializer;
    physician_team: number;
    created_by: PhysicianSerializer;
    signed_by: PhysicianSerializer;
    // @ts-ignore
    edited_by: PhysicianSerializer; // | number
    category: string;
    // @ts-ignore
    patient: PatientWithFacilitySerializer | PatientSimpleSerializer;
    level_of_care: string;
    assigned_users: TaskAssignedUserSimpleSerializer[];
    duration: number;
    date: Date | string;
    type: string;
    description: string;
    note: string;
    created_at: Date | string;
    modified_at: Date | string;
    need_to_fax: boolean;
    need_to_sms: boolean;
    is_faxed: boolean;
    // TODO DYNAMIC FORMS: get rid of this property
    dynamic_form_values: any;
    billable: boolean;
    // TODO: check VisitSimpleSerializer in TaskSimpleSerializer
    // @ts-ignore
    visit: number;
    verify_comment: string;
    status: TaskStatus;
    fax_template: string;
    e_prescribing: EPrescribingSerializer;
    includes_e_prescription: boolean;
    work_list_item: number;
    billing_validation: any;
    scheduling_review: CachedSchedulingReviewSerializer;
    signed_at: any;
    // @ts-ignore
    source_patient: number;
    sourcePatient: PatientTaskProposalSerializer;
    // @ts-ignore
    patient_document: number | PatientDocumentSerializer;
    @ModelBaseAccessor(TaskBillingStatusDetails) last_billing_status: TaskBillingStatusDetails;
    // @ts-ignore
    approved_for_billing: TaskStatusHistorySerializer | number;

    @ModelBaseAccessor(FaxStatus) fax_status: FaxStatus;
    @ModelBaseAccessor(TaskStatusHistoryEntry) last_fax_status: TaskStatusHistoryEntry;
    // @ts-ignore
    @ModelBaseAccessor(LabTestReview) lab_test_review: LabTestReview;
    // @ts-ignore
    @ModelBaseAccessor(TreatmentReview) treatment_review: TreatmentReview;
    @ModelBaseAccessor(EventReview) event_review: EventReview;
    @ModelBaseAccessor(VitalReview) vital_review: VitalReview;
    @ModelBaseAccessor(OrderReview) order_review: OrderReview;
    @ModelBaseAccessor(ProcedureReview) procedure_review: ProcedureReview;
    @ModelBaseAccessor(ImagingResultReview) imaging_result_review: ImagingResultReview;
    @ModelBaseAccessor(ClinicalResultReview) clinical_test_result_review: ClinicalResultReview;
    @ModelBaseAccessor(AllergyReview) patient_allergy_review: AllergyReview;
    @ModelBaseAccessor(ImmunizationReview) immunization_review: ImmunizationReview;
    @ModelBaseAccessor(PastMedicalHx) past_medical_hx: PastMedicalHx;
    @ModelBaseAccessor(AssessmentPlan) assessment_plan: AssessmentPlan;
    @ModelBaseAccessor(PhysicianOrder) physician_order: PhysicianOrder;
    @ModelBaseAccessor(ProblemList) problem_list: ProblemList;

    history_of_present_illness: HistoryOfPresentIllnessReadSerializer;
    physical_examination: PhysicalExaminationReadSerializer;
    review_of_systems: ReviewOfSystemsSerializer;
    family_history: FamilyHistorySerializer;
    social_history: SocialHistoryReadSerializer;
    visit_details: VisitDetailsSerializer;
    vital_examination: VitalExaminationSerializer;
    demographics: DemographicsSerializer;
    patient_information: PatientInformationSerializer;
    awareness_of_consequences: BehavioralPatientAwarenessOfConsequencesOfIllnessAndBehaviorOnSerializer;
    baseline_evaluation: BehavioralBaselineEvaluationSerializer;
    behavioral_family_history: BehavioralFamilyHistorySerializer;
    behavioral_physical_examination: BehavioralPhysicalExaminationReadSerializer;
    behavioral_review_of_systems: BehavioralReviewOfSystemsSerializer;
    behavioral_social_history: BehavioralSocialHistoryReadSerializer;
    developmental_history: BehavioralDevelopmentalHistorySerializer;
    mental_status_exam: BehavioralMentalStatusExamReadSerializer;
    past_psychiatric_history: BehavioralPastPsychiatricHistorySerializer;
    patient_complexity: PatientComplexitySerializer;
    psychosocial_circumstances: BehavioralPsychosocialCircumstancesInfluencingHealthStatusSerializer;
    questionnaire_responses: FHIRQuestionnaireResponseSerializer;
    symptoms_of_illness: BehavioralSymptomsOfIllnessSerializer;
    trauma_evaluation: BehavioralTraumaEvaluationSerializer;
    coded_notes: CodedNoteReadSerializer[];

    // TaskBillingSerializer
    task_download_history: TaskDownloadHistorySerializer[];
    assessment_plan_no_of_dx: number;
    billing_invalidity_reasons: any;
    is_valid_for_billing: boolean;

    meta: any;

    static viewSet = APIService.TaskViewSet;

    static compareSort(a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime() ||
            getId(b.patient) - getId(a.patient) ||
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }

    // assign(x: TaskSerializer | TaskSimpleSerializer) {
    assign(x: TaskSerializer) {
        const {
            history_of_present_illness,
            visit_details,
            social_history,
            family_history,
            review_of_systems,
            physical_examination,
            ...rest
        } = x;
        if (history_of_present_illness) this.history_of_present_illness = Object.assign(this.history_of_present_illness || {}, history_of_present_illness);
        if (visit_details) this.visit_details = Object.assign(this.visit_details || {}, visit_details);
        if (social_history) this.social_history = Object.assign(this.social_history || {}, social_history);
        if (family_history) this.family_history = Object.assign(this.family_history || {}, family_history);
        if (review_of_systems) this.review_of_systems = Object.assign(this.review_of_systems || {}, review_of_systems);
        if (physical_examination) this.physical_examination = Object.assign(this.physical_examination || {}, physical_examination);
        if (x.source_patient && typeof x.source_patient !== 'number') {
            this.sourcePatient = x.source_patient;
            this.source_patient = (x.source_patient as PatientTaskProposalSerializer).id;
        }

        super.assign(rest);
    }

    static isDraft(x): boolean {
        return x.status && x.status !== 'SAVED';
    }

    get isDraft(): boolean {
        return Task.isDraft(this);
    }

    static isSigned(x): boolean {
        return x.status === 'SAVED';
    }

    get isSigned(): boolean {
        return Task.isSigned(this);
    }

    get isEditable() {
        return !this.last_billing_status || this.last_billing_status.isEditable;
    }

    static billingCodeValidForVisitBilling(x) {
        return !!x.visit_details?.billing_code;
    }

    get billingCodeValidForVisitBilling() {
        return Task.billingCodeValidForVisitBilling(this);
    }

    static validForVisitBilling(x) {
        return !this.isDraft(x) && this.billingCodeValidForVisitBilling(x) && !!x.assessment_plan_no_of_dx;
    }

    @GetterCache()
    get validForVisitBilling() {
        return Task.validForVisitBilling(this);
    }

    static noCharge(x) {
        return x.type === 'VISIT_FOLLOW_UP_CARE_NOTE' || x.visit_details?.billing_code === '111x1';
    }

    get noCharge() {
        return Task.noCharge(this);
    }

    static needsBillingReview(x) {
        return x.visit_details?.billing_code === '222x2';
    }

    getSerialized(obj: any = this): any {
        const {fax_status, last_fax_status, ...task} = obj;
        return super.getSerialized(task);
    }

    get visitId() {
        return getId(this.visit);
    }

    @GetterCache()
    get physicianTeam(): PhysicianTeam {
        return this.physician_team && PhysicianTeam.get(this.physician_team);
    }

    @GetterCache()
    get facility(): Facility {
        const facId = getId(this.patient.facility) || getId(this.physicianTeam?.facility);
        return facId && Facility.get(facId);
    }

    @GetterCache()
    get invalidityStructured() {
        if (!this.billing_invalidity_reasons?.length) return;
        const cats: {key: string; category: string; reasons: string[]}[] = [];
        this.billing_invalidity_reasons.forEach(x => {
            let key = x.split('_')[0];
            let category = VISIT_BILLING_CONSTANTS.InvalidReasonsCategory[key];
            if (!category) {
                key = null;
                category = 'Other';
            }
            const cat = cats.find(c => c.key === key);
            const reason = VISIT_BILLING_CONSTANTS.InvalidReasonsDesc[x] || x;
            if (cat) {
                cat.reasons.push(reason);
            } else {
                cats.push({key, category, reasons: [reason]});
            }
        });
        return cats;
    }

    get billingStatus() {
        return this.last_billing_status?.status;
    }

    static getStatusColorClass(status: TaskStatus) {
        switch (status) {
            case 'SAVED':
                return 'success';
            case 'DRAFT':
            case 'RX_FINALIZATION_STARTED':
            case 'RX_FINALIZATION_PENDING':
                return 'warning';
            case 'RX_FINALIZATION_ERROR':
                return 'danger';
            default:
                return null;
        }
    }

    static getFaxTemplateName(template: FaxTemplate) {
        switch (template) {
            case 'facility_task':
                return 'Orders';
            case 'task':
                return 'Note';
            default:
                return '';
        }
    }
}
