import {ErrorHandler, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SlidePanel} from '../portal/slide-panel/slide-panel';
import {HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import {OrganizationFacilityDetails} from './facility-chooser.service';

@Injectable({providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
    static chunkLoadError = new Subject<void>();
    static user;
    static slidePanelStack: SlidePanel[] = [];
    static activeFilter: {[key: string]: any};
    static fcDetails: OrganizationFacilityDetails;

    handleError(error: any) {
        SentryErrorHandler.handleError(error);
    }

    static extractError(error) {
        if (error?.ngOriginalError) {
            error = error?.ngOriginalError;
        }

        if (error instanceof Error) {
            return error;
        }

        if (typeof error === 'string') {
            return Error(error);
        }

        if (error instanceof HttpErrorResponse) {
            if (typeof error.error === 'string') {
                Sentry.setContext('Error Object', error);
                return Error(error.message);
            }

            if (error.error instanceof Error) {
                return error.error;
            }

            if (error.error && typeof error.error === 'object') {
                Sentry.setContext('Error Object', error.error);
                return Error(error.message);
            }

            Sentry.setContext('Error Object', error.error);
            return Error(error.message);
        }
        return JSON.stringify(error);
    }

    static handleError(error) {
        if (error?.status === 401 ||
            error?.status === 400 && ['/refresh/', '/auth/', '/login/'].some(x => error.url?.includes(x))) {
            return;
        }

        error = this.extractError(error);

        const chunkFailedMessage = /Loading chunk .+ failed/;
        if (error.toString?.().includes('Failed to fetch dynamically imported module') || chunkFailedMessage.test(error)) {
            SentryErrorHandler.chunkLoadError.next();
        }

        const slidePanelStack = {
            components: SentryErrorHandler.slidePanelStack.map(x => ({
                component: x.component?.name,
                title: x.panelComponent?.title || document.querySelector('.panel-title')?.textContent,
                componentInputs: x.componentInputs,
            })),
        };

        const activePanel = SentryErrorHandler.slidePanelStack.length > 0 ? SentryErrorHandler.slidePanelStack[SentryErrorHandler.slidePanelStack.length - 1] : null;
        const slidePanelActive = activePanel && {
            component: activePanel.component?.name,
            title: activePanel.panelComponent?.title || document.querySelector('.panel-title')?.textContent,
            componentInputs: activePanel.componentInputs,
        };

        const fcDetails = this.fcDetails;

        Sentry.captureException(error || 'Unknown error', {
            contexts: {
                slidePanelStack,
                slidePanelActive,
                facility: {value: fcDetails?.value || null, name: fcDetails?.name || null},
                filters: SentryErrorHandler.activeFilter,
                frontend: {version: window['siteVersion']},
            },
        });
        console.error(error);
        // Sentry.showReportDialog({eventId});
    }
}
