import {Pipe, PipeTransform} from '@angular/core';
import {FacilityChooserService} from '../../@core/facility-chooser.service';
import {toDateTime} from '../../utils/date.utils';
import {DateTime} from 'luxon';

@Pipe({
    name: 'simpleDate',
})
export class SimpleDatePipe implements PipeTransform {
    transform(value: any, needsTimezone = true, needsTime = false, showDay = false): string | null {
        return SimpleDatePipe.transform(value, needsTimezone, needsTime, showDay);
    }

    static transform(value: any, needsTimezone = true, needsTime = false, showDay = false): string | null {
        if (!value) return '';
        const dt = needsTimezone ? toDateTime(value, {zone: FacilityChooserService.timezone}) : toDateTime(value);
        const sameYear = dt.year == DateTime.now().year;

        const dayText = showDay ? `${dt.weekdayShort}, ` : '';
        const dateText = dt.year ? `${dayText}${dt.toFormat('MMM')} ${dt.toFormat('d')}${sameYear ? '' : `, ${dt.year}`}` : '';

        return dateText + (needsTime ? `, ${dt.toFormat('h:mm a')}` : '');
    }
}
