export class Payer {
    static isMedicaid(payer): boolean {
        return !!payer && (payer.toLowerCase().includes('medicaid') || payer.toLowerCase().includes('mcaid'));
    }

    static isMedicaidPending(payer): boolean {
        return !!payer && payer.toLowerCase().includes('medicaid pending');
    }

    static isPrivate(payer): boolean {
        return !!payer && payer.toLowerCase().includes('private');
    }

    static isHospice(payer): boolean {
        return !!payer && payer.toLowerCase().includes('hospice');
    }

    static isLTC(payer): boolean {
        return !!payer && (payer.toLowerCase() === 'ltc' || !Payer.isHospice(payer) && (Payer.isMedicaid(payer) || Payer.isPrivate(payer)));
    }

    static isLongTermResident(payer): boolean {
        return Payer.isHospice(payer) || Payer.isLTC(payer);
    }

    static isSkilled(payer): boolean {
        return !!payer && (payer.toLowerCase() === 'skilled' || !Payer.isLongTermResident(payer));
    }

    static isPending(payer): boolean {
        return !!payer && payer.toLowerCase().indexOf('pending') > -1;
    }
}
