import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TwoFactorAuthService} from './two-factor-auth.service';
import {NgForm} from '@angular/forms';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {takeUntil} from 'rxjs/operators';
import {UserPasscodeSerializer} from '../../../../@core/api.service';

@Component({
    selector: 'app-two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
})
export class TwoFactorAuthComponent extends UnsubscribeComponent implements OnInit {
    @ViewChild('f') form: NgForm;
    passcode: string;
    passcodeInfo: UserPasscodeSerializer;

    constructor(private ngbActiveModal: NgbActiveModal,
                private twoFactoAuthService: TwoFactorAuthService) {
        super();
    }

    ngOnInit() {
        this.twoFactoAuthService.passcodeError$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.form.controls['auth-code'].setErrors({});
        });
    }

    submit() {
        this.twoFactoAuthService.submitPasscode(this.passcode);
    }

    closeModal() {
        this.ngbActiveModal.close();
    }
}
