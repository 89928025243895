import {inject, Injectable, NgZone} from '@angular/core';
import {BroadcastChannelBase} from './broadcast-channel.base';

export type AuthBroadcastChannelMessageActions =
    'new_user'
    | 'update_tokens'
    | 'invalidate_tokens'
    | 'show_toast'
    | 'show_login_modal'
    | 'dismiss_login_modal';

export type AuthBroadcastChannelPayload = {user?: {id: number}; authToken?: string; refreshToken?: string};

@Injectable({
    providedIn: 'root',
})
export class AuthBroadcastService extends BroadcastChannelBase<AuthBroadcastChannelMessageActions, AuthBroadcastChannelPayload> {
    constructor() {
        super('auth_channel', inject(NgZone));
    }
}

