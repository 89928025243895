export const SNOMED_VALUESET_OIDS = {
    GENDER_IDENTITIES: '2.16.840.1.113762.1.4.1021.32',
    SMOKING_STATUSES: '2.16.840.1.113883.11.20.9.38',
    PROBLEM_TYPES: '2.16.840.1.113883.3.88.12.3221.7.2',
    PROBLEMS: '2.16.840.1.113883.3.88.12.3221.7.4',
    SDOH_CONDITIONS: '2.16.840.1.113762.1.4.1196.788',
    SDOH_PROCEDURES: '2.16.840.1.113762.1.4.1196.789',
    SDOH_INTERVENTIONS: '2.16.840.1.113762.1.4.1196.790',
    ALLERGY_TYPES: '2.16.840.1.113883.3.88.12.3221.6.2',
    ALLERGIES: '2.16.840.1.113762.1.4.1010.1',
    MENTAL_STATUSES: '2.16.840.1.113762.1.4.1240.1',
    BODY_SITES: '2.16.840.1.113883.3.88.12.3221.8.9',
    HEALTH_STATUSES: '2.16.840.1.113883.1.11.20.12',
    CHRONIC_KIDNEY_DISEASE_STAGE_5: '2.16.840.1.113883.3.526.3.1002',
    MEDICAL_REASON: '2.16.840.1.113883.3.526.3.1007',
    PATIENT_REASON: '2.16.840.1.113883.3.526.3.1008',
    PATIENT_DECLINED: '2.16.840.1.113883.3.526.3.1582',
    FOLLOW_UP_FOR_ABOVE_NORMAL_BMI: '2.16.840.1.113883.3.600.1.1525',
    FOLLOW_UP_FOR_BELOW_NORMAL_BMI: '2.16.840.1.113883.3.600.1.1528',
    ENCOUNTER_TO_EVALUATE_BMI: '2.16.840.1.113883.3.600.1.1751',
    DIABETES: '2.16.840.1.113883.3.464.1003.103.12.1001',
    REFERRALS_WHERE_WEIGHT_ASSESSMENT_MAY_OCCUR: '2.16.840.1.113883.3.600.1.1527',
    TOBACCO_USE: '2.16.840.1.113883.3.526.3.1170',
    TOBACCO_NON_USER: '2.16.840.1.113883.3.526.3.1189',
    TOBACCO_USE_CESSATION_COUNSELING: '2.16.840.1.113883.3.526.3.509',
    HOSPICE_CARE_AMBULATORY: '2.16.840.1.113883.3.526.3.1584',
    PATIENT_DECLINES_DEPRESSION_SCREENING: '2.16.840.1.113762.1.4.1116.495',
    ARSANA_FAMILY_MEMBERS: 'ARSANA_FAMILY_MEMBERS',
    OverweightOrObese: '2.16.840.1.113762.1.4.1047.502',
    Underweight: '2.16.840.1.113883.3.526.3.1563',
    FollowUpForAboveNormalBmi: '2.16.840.1.113883.3.600.1.1525',
    FollowUpForBelowNormalBmi: '2.16.840.1.113883.3.600.1.1528',
    EncounterToEvaluateBmi: '2.16.840.1.113883.3.600.1.1751',
    ReferralsWhereWeightAssessmentMayOccur: '2.16.840.1.113883.3.600.1.1527',
    CognitiveAssessment: '2.16.840.1.113883.3.526.3.1332',
    DementiaMentalDegenerations: '2.16.840.1.113883.3.526.3.1005',
    BipolarDisorder: '2.16.840.1.113883.3.67.1.101.1.128',
    ReferralForAdolescentDepression: '2.16.840.1.113883.3.526.3.1570',
    ReferralForAdultDepression: '2.16.840.1.113883.3.526.3.1571',
    FollowUpForAdolescentDepression: '2.16.840.1.113883.3.526.3.1569',
    FollowUpForAdultDepression: '2.16.840.1.113883.3.526.3.1568',
    ChronicKidneyDiseaseStage5: '2.16.840.1.113883.3.526.3.1002',
    Diabetes: '2.16.840.1.113883.3.464.1003.103.12.1001',
    EndStageRenalDisease: '2.16.840.1.113883.3.526.3.353',
    TobaccoUser: '2.16.840.1.113883.3.526.3.1170',
    TobaccoNonUser: '2.16.840.1.113883.3.526.3.1189',
    TobaccoUseCessationCounseling: '2.16.840.1.113883.3.526.3.509',
    HospiceEncounter: '2.16.840.1.113883.3.464.1003.1003',
    HospiceCareAmbulatory: '2.16.840.1.113883.3.526.3.1584',
    HospiceDiagnosis: '2.16.840.1.113883.3.464.1003.1165',
    PregnancyObstetricOrMaternalDiagnoses: '2.16.840.1.113883.3.600.1.1623',
    PalliativeCareDiagnosis: '2.16.840.1.113883.3.464.1003.1167',
    PalliativeCareEncounter: '2.16.840.1.113883.3.464.1003.101.12.1090',
    PalliativeCareIntervention: '2.16.840.1.113883.3.464.1003.198.12.1135',
    MedicalReason: '2.16.840.1.113883.3.526.3.1007',
    PatientDeclined: '2.16.840.1.113883.3.526.3.1582',
    PatientReason: '2.16.840.1.113883.3.526.3.1008',
    BehavioralneuropsychAssessment: '2.16.840.1.113883.3.526.3.1023',
    PhysicalTherapyEvaluation: '2.16.840.1.113883.3.526.3.1022',
};
