/* eslint-disable */
import {map, take, tap} from 'rxjs/operators';
import {combineLatest, of, ReplaySubject} from 'rxjs';
import {getId, Id, isId} from '../utils/type.utils';
import {CheckPatientCache} from '../@core/utils/check-patient-cache';
import {AccessorUtils, Meta} from '../utils/accessor.utils';

export type ModelType<T> = typeof ModelBase & {
    new (...args): T;
};

export function assignOrCreate<T extends ModelBase>(type: ModelType<T>, data, ...args): T {
    if (!data || !data.id) return;

    if (!ModelBase.descendants.includes(type)) ModelBase.descendants.push(type);

    const o = type.get(data['id']);
    if (o) {
        if (o !== data) o.assign(data, ...args);
        return o;
    }

    return new type(data, ...args);
}

export class ModelListProxyHandler {
    args: any[];

    constructor(private T: any, private returnPromise = false, ...args) {
        this.args = args;
    }

    get(obj, prop) {
        if (prop === 'indexOf') return e => obj.indexOf(isId(e) ? e : e.id);
        if (prop === 'length') return obj[prop];
        if (prop === '__proxy_original') return obj;
        return isId(obj[prop]) ? (this.returnPromise ? this.T.retrieve(obj[prop]).toPromise() : this.T.get(obj[prop])) : obj[prop];
    }

    set(obj, prop, value) {
        if (typeof value === 'object' && value !== null && 'id' in value) {
            if (!(value instanceof this.T)) assignOrCreate(this.T, value, ...this.args);

            obj[prop] = value.id;
        } else {
            obj[prop] = value;
        }
        return true;
    }

    has(obj, prop) {
        if (prop === '__proxy_original') return true;
        return prop in obj;
    }
}

export class ModelBase {
    protected static isModelBase = true;
    protected static cache_key = '__assignable_cache';
    static clearCacheOnUserChange = true;

    static clear<T extends typeof ModelBase>() {
        Object.create(this.prototype as InstanceType<T>).constructor[this.cache_key] = {};
    }

    static get<T extends typeof ModelBase>(id: Id) {
        id = getId(id);

        const cache = this[this.cache_key];

        return cache && id in cache ? cache[id] : null;
    }

    static retrieve<T extends typeof ModelBase>(id: Id, forceFetch = false, preferDetail = true) {
        const e = (!forceFetch || (id as number) < 0) && this.get(id);

        if (e) return of(e);

        if (!this.hasOwnProperty('viewSet') || !this['viewSet'].hasOwnProperty('retrieve')) throw Error('ViewSet not set, define static property viewSet on model.');

        return (preferDetail && this['viewSet']['retrieve_detail'] || this['viewSet']['retrieve'])(id).pipe(map(x => {
            const o = assignOrCreate(this, x);

            if ('__data' in this && !this['__data_invalidated']) {
                this.fetch().pipe(take(1)).subscribe(__data => {
                    __data.push(o);
                    (this['__data'] as any).next(__data);
                });
            }

            return o;
        }));
    }

    static transformData(obs) {
        return obs;
    }

    static invalidate<T extends typeof ModelBase>() {
        this['__data_invalidated'] = true;
        if (this['__data']?.observers.length) this.fetch();
    }

    static list<T extends typeof ModelBase>(filter?: any, ...assignArgs) {
        if (!this.hasOwnProperty('viewSet') || !this['viewSet'].hasOwnProperty('list')) throw Error('ViewSet not set, define static property viewSet on model.');

        const deps = this.hasOwnProperty('dataDeps') ? this['dataDeps'].map(x => x()) : [];

        return combineLatest([this['viewSet']['list'](filter), ...deps]).pipe(
            map(x => (x[0]['results'] || x[0] as any[]).map(y => assignOrCreate(this, y, ...assignArgs)))
        );
    }

    static fetch<T extends typeof ModelBase>(): ReplaySubject<any> {
        if (!('__data' in this)) {
            this['__data'] = new ReplaySubject(1);
            this['__data_invalidated'] = true;

            if (this.hasOwnProperty('invalidateOn')) this['invalidateOn'].subscribe(() => this.invalidate());
        }

        if (this['__data_invalidated']) {
            if (!this.hasOwnProperty('viewSet') || !this['viewSet'].hasOwnProperty('list')) throw Error('ViewSet not set, define static property viewSet on model.');

            this['__data_invalidated'] = false;

            this.transformData(this.list().pipe(tap(() => this.clear()))).subscribe(x => this['__data'].next(x));
        }

        return this['__data'];
    }

    static create<T extends typeof ModelBase>(data) {
        if (!this.hasOwnProperty('viewSet') || !this['viewSet'].hasOwnProperty('create')) throw Error('ViewSet not set, define static property viewSet on model.');

        return this['viewSet']['create'](data).pipe(map(x => {
            const o = new this(x);

            if ('__data' in this && !this['__data_invalidated']) {
                this.fetch().pipe(take(1)).subscribe(__data => {
                    __data.push(o);
                    (this['__data'] as any).next(__data);
                });
            }

            return o;
        }));
    }

    __addToCache() {
        if (!this.constructor[this.constructor['cache_key']]) this.constructor[this.constructor['cache_key']] = {};
        this.constructor[this.constructor['cache_key']][this['id']] = this;

        const pid = this.constructor['patientDetailKey'] && getId(this['patient']);
        if (pid) CheckPatientCache.add(pid, this.constructor['patientDetailKey'], this['id']);
    }

    constructor(x: any = {}, ...args) {
        Object.entries(Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this))).forEach(([k, descriptor]) => {
            if (typeof descriptor.get === 'function' && descriptor.enumerable) {
                Object.defineProperty(this, k, descriptor);
            }
        });

        this.assign(x, ...args);

        if (x.id) this.__addToCache();
    }

    static setAccessor(target, name, type, ...args) {
        const idKey = `${name}_id`;
        if (!(idKey in target)) {
            Object.defineProperty(target, idKey, {writable: true});

            Object.defineProperty(target, name, {
                get() {
                    return this[idKey] ? type.get(this[idKey]) : null;
                },
                set(v) {
                    if (typeof v === 'object' && v !== null && 'id' in v) {
                        if (!(v instanceof type)) assignOrCreate(type, v, ...args);

                        this[idKey] = v['id'];
                    } else {
                        this[idKey] = v;
                    }
                },
                enumerable: true,
            });
        }
    }

    setAccessor(name, type, ...args) {
        const val = this[name];
        ModelBase.setAccessor(this, name, type, ...args);
        if (val) this[name] = val;
    }

    static setListAccessor(target, name, type, ...args) {
        const proxyKey = `__${name}_proxy`;
        const createProxy = () => new Proxy([], new ModelListProxyHandler(type));
        if (!(proxyKey in target)) {
            Object.defineProperty(target, name, {
                get() {
                    return Meta.get(this, proxyKey, createProxy);
                },
                set(v) {
                    const arr = Meta.get(this, proxyKey, createProxy);
                    arr.splice(0, arr.length, ...v.map(x => isId(x) ? x : assignOrCreate(type, x, ...args)));
                },
                enumerable: true,
            });
        }
    }

    setListAccessor(name, type, ...args) {
        const val = this[name];
        ModelBase.setListAccessor(this, name, type, ...args);
        if (val) this[name] = val;
    }

    setNonEnumerable(propertyKey: string) {
        const descriptor = Object.getOwnPropertyDescriptor(this, propertyKey) || {writable: true};
        if (descriptor.enumerable != false) {
            descriptor.enumerable = false;
            Object.defineProperty(this, propertyKey, descriptor);
        }
    }

    assign(x: any, ...args) {
        AccessorUtils.clearCache(this);
        Object.assign(this as any, x);
    }

    remove() {
        if (!this.constructor.hasOwnProperty('viewSet') || !this.constructor['viewSet'].hasOwnProperty('destroy')) throw Error('ViewSet not set, define static property viewSet on model.');
        return this.constructor['viewSet']['destroy'](this['id']).pipe(tap(() => {
            this.constructor[this.constructor['cache_key']][this['id']] = null;
            if (this.constructor['__data']) this.constructor['__data'].pipe(take(1)).subscribe(x => this.constructor['__data'].next(x.filter(y => y['id'] != this['id'])));
        }));
    }

    update(data = null) {
        if (!this.constructor.hasOwnProperty('viewSet') || !this.constructor['viewSet'].hasOwnProperty('partial_update')) throw Error('ViewSet not set, define static property viewSet on model.');

        if (!data) data = this.getSerialized();

        return this.constructor['viewSet']['partial_update'](this['id'], data).pipe(map(x => {
            this.assign(x);
            return this;
        }));
    }

    getSerialized(obj: any = this) {
        const processValue = v => {
            if (Array.isArray(v)) {
                // if ('__proxy_original' in v) v = v['__proxy_original'];
                v = v.map(x => processValue(x));
            } else if (typeof v === 'object' && v !== null && !(v instanceof Date)) {
                if (typeof v['getSerialized'] === 'function') v = v['getSerialized']();
                // else if (v.hasOwnProperty('id')) v = v['id'];
                else if (typeof v === 'function') v = null;
                else (v = processObject(v));
            }

            return v;
        };

        const processObject = (obj: {[key: string]: any}) => {
            const t: any = {};
            this.keys(obj).forEach(k => {
                // TODO DYNAMIC FORMS: find a better solution to avoid circular processing
                if (k != 'related_tasks' && k != 'reviews') {
                    const v = processValue(obj[k]);

                    if (typeof v !== 'undefined') t[k] = v;
                }
            });

            return t;
        };

        return processObject(obj);
    }

    keys(obj: any = this) {
        return Object.keys(obj).filter(k => !k.startsWith('_'));
    }

    create() {
        if (!this.constructor.hasOwnProperty('viewSet') || !this.constructor['viewSet'].hasOwnProperty('create')) throw Error('ViewSet not set, define static property viewSet on model.');

        if (this['id']) throw new Error('Object already created');

        return this.constructor['viewSet']['create'](this.getSerialized()).pipe(map(x => {
            this.assign(x);
            this.__addToCache();
            return this;
        }));
    }

    save() {
        return this['id'] ? this.update() : this.create();
    }

    retrieve() {
        if (!this.constructor.hasOwnProperty('viewSet') || !this.constructor['viewSet'].hasOwnProperty('retrieve')) throw Error('ViewSet not set, define static property viewSet on model.');

        return this.constructor['viewSet']['retrieve'](this['id']).pipe(map(x => {
            this.assign(x);
            return this;
        }));
    }

    removeFromCache() {
        if (this.constructor[this.constructor['cache_key']] && this.constructor[this.constructor['cache_key']][this['id']]) delete this.constructor[this.constructor['cache_key']][this['id']];
    }

    static descendants = [];
}

export function ModelBaseAccessor(type) {
    return (target: any, propertyKey: string) => {
        ModelBase.setAccessor(target, propertyKey, type);
    };
}

export function ModelBaseListAccessor(type) {
    return (target: any, propertyKey: string) => {
        ModelBase.setListAccessor(target, propertyKey, type);
    };
}
