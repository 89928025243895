import {Injectable} from '@angular/core';
import {UserAuthService} from './user-auth.service';
import {Observable, Observer, ReplaySubject, Subject} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {FacilityDetailedSerializer, OrganizationSerializer} from './api.service';
import {User} from '../models/user';
import {getSorted} from '../@theme/helpers';
import {SentryErrorHandler} from './sentry-error-handler';
import {Organization} from '../models/organization';
import {Facility} from '../models/facility';

export interface OrganizationFacility {
    organization: number;
    facility: number;
}

export interface OrganizationFacilityDetails {
    organization: OrganizationSerializer;
    facility: FacilityDetailedSerializer;
    value: OrganizationFacility;
    name: string;
    from_customer_only?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FacilityChooserService extends ReplaySubject<OrganizationFacility> {
    static selectedDetails: OrganizationFacilityDetails;
    static topTimezone: string;
    private _options: OrganizationFacilityDetails[];
    private _user: User;
    private _localStorageKey = 'organizationFacility';
    private _silentObserversCount = 0;
    private _isObservedOpenly: boolean;

    openSelect$ = new Subject<boolean>();
    isObservedOpenly$ = new ReplaySubject<boolean>(1);

    constructor(private userAuth: UserAuthService, private storage: LocalStorageService) {
        super(1);
        this.userAuth.user.subscribe(u => {
            this._user = u;
            this._processOptions();
            this._initSelected();
        });
    }

    static get selected(): OrganizationFacility {
        return FacilityChooserService.selectedDetails ? FacilityChooserService.selectedDetails.value : null;
    }

    get selected(): OrganizationFacility {
        return FacilityChooserService.selected;
    }

    get selectedDetails(): OrganizationFacilityDetails {
        return FacilityChooserService.selectedDetails;
    }

    get selectedName(): string {
        return this.selectedDetails ? this.selectedDetails.name : null;
    }

    static get timezone() {
        if (!this.selectedDetails) return null;
        return (this.selectedDetails.facility?.timezone) || (this.selectedDetails.organization?.timezone) || this.topTimezone || null;
    }

    get timezone() {
        return FacilityChooserService.timezone;
    }

    get options(): OrganizationFacilityDetails[] {
        return this._options;
    }

    setSelectedFacility(facility: OrganizationFacility | number) {
        const option = typeof facility === 'number' ?
            this._options.find(x => x.value.facility === facility) :
            this._options.find(x => x.value.organization === facility.organization && x.value.facility === facility.facility);
        if (option && option !== this.selectedDetails) this._update(option);
    }

    subscribe(observerOrNext?: Partial<Observer<OrganizationFacility>> | ((value: OrganizationFacility) => void)) {
        const sub = super.subscribe(observerOrNext);
        this.processIsObservedOpenly();
        sub.add(() => this.processIsObservedOpenly());
        return sub;
    }

    getSilently() {
        return new Observable<OrganizationFacility>(subscriber => {
            this.subscribe(subscriber);
            this._silentObserversCount++;
            return () => {
                this._silentObserversCount--;
                this.processIsObservedOpenly();
            };
        });
    }

    processIsObservedOpenly() {
        setTimeout(() => {
            const isObservedOpenly = this.observers.length > this._silentObserversCount;
            if (this._isObservedOpenly !== isObservedOpenly) {
                this._isObservedOpenly = isObservedOpenly;
                this.isObservedOpenly$.next(isObservedOpenly);
            }
        });
    }

    private _update(option: OrganizationFacilityDetails) {
        FacilityChooserService.selectedDetails = option;
        SentryErrorHandler.fcDetails = option;
        this.storage.store(this._localStorageKey, this.selected);
        this.next(this.selected);
    }

    private _processOptions() {
        if (this._user?.organizations?.length) {
            const activeFacilities: OrganizationFacilityDetails[] = [];
            const inactiveFacilities: OrganizationFacilityDetails[] = [];

            getSorted(this._user.organizations).forEach(o => {
                const oActiveFacilities: OrganizationFacilityDetails[] = [];

                getSorted(o.facilities).forEach(f => (f.is_inactive ? inactiveFacilities : oActiveFacilities).push(this._getOption(o, f)));

                if (oActiveFacilities.length > 1) {
                    activeFacilities.push(this._getOption(o, null, `${o.name} - All`, oActiveFacilities.every(x => x.from_customer_only)));
                }
                activeFacilities.push(...oActiveFacilities);
            });

            this._options = [...activeFacilities, ...getSorted(inactiveFacilities)];
            if (this._options.length > 1) {
                this._options = [this._getOption(null, null, 'All Facilities'), ...this._options];
            }
        } else {
            this._options = [];
        }
        this._processTimezones();
    }

    private _getOption(organization: Organization | null, facility: Facility | null, name = facility?.name, from_customer_only = facility?.from_customer_only || false) {
        return {organization, facility, value: {organization: organization?.id || 0, facility: facility?.id || 0}, name, from_customer_only};
    }

    private _processTimezones() {
        interface tzCount {
            name: string;
            count: number;
        }

        const topTz: tzCount = this._options.reduce((tzs: tzCount[], option: OrganizationFacilityDetails) => {
            const tz = (option.facility?.timezone) || (option.organization?.timezone) || null;
            if (tz) {
                const tzAggr = tzs.find(x => x.name === tz);
                tzAggr ? tzAggr.count++ : tzs = [...tzs, {name: tz, count: 1}];
            }
            return tzs;
        }, []).reduce((top: tzCount, curr: tzCount) => !top || curr.count > top.count ? curr : top, null);
        FacilityChooserService.topTimezone = topTz ? topTz.name : null;
    }

    private _initSelected() {
        const u = this._user;
        if (u?.organizations?.length) {
            const stored = this.storage.retrieve(this._localStorageKey);
            const option = stored ? this.options.find(x => x.value.organization === stored.organization && x.value.facility === stored.facility) : null;
            this.setSelectedFacility((option || this.options[0]).value);
        }
    }

    openSelect() {
        this.openSelect$.next(true);
    }
}
