import {assignOrCreate, ModelBase} from './model-base';
import {
    APIService,
    DrugbankProductConceptRxCuiSerializer,
    DrugbankProductConceptRxCuiViewSetQueryParams,
    DrugbankProductConceptSimpleSerializer,
    DrugbankProductSimpleSerializer,
    DrugbankProductViewSetQueryParams,
    DrugbankProductWithRxCuiSerializer,
} from '../@core/api.service';
import {MethodCache} from '../utils/accessor.utils';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {DRUGBANK_PRODUCT_VALUESET_OIDS} from '../@core/drugbank_product_valueset_oids';

export class DrugbankProduct extends ModelBase implements DrugbankProductSimpleSerializer {
    id: number;
    name: string;
    prescribable_name: string;
    legacy_name: string;
    displayName: string;
    schedule: string;
    dosage_form: string;
    ayva_ddid: string;
    route: string;
    code: string;
    rxcui: string;
    description: string;
    drug: any;

    static viewSet = APIService.DrugbankProductViewSet;

    assign(x: DrugbankProductSimpleSerializer | DrugbankProductWithRxCuiSerializer, ...args) {
        super.assign(x, ...args);
        if (!this.prescribable_name) this.prescribable_name = this.name;
        this.displayName = this.legacy_name || this.prescribable_name;
        this.code = (this.rxcui) ? this.rxcui : this.ayva_ddid;
    }

    @MethodCache('convertObject')
    static list(filters: DrugbankProductViewSetQueryParams): Observable<DrugbankProduct[]> {
        return super.list(filters).pipe(shareReplay(1));
    }

    @MethodCache()
    static getValueSet(category: keyof typeof DRUGBANK_PRODUCT_VALUESET_OIDS) {
        return APIService.DrugbankValueSetViewSet.list({category}).pipe(
            map(res => res.results.map(x => assignOrCreate(DrugbankProduct, x))),
            shareReplay(1),
        );
    }
}

export class DrugbankProductConceptRxCui extends ModelBase implements DrugbankProductConceptRxCuiSerializer {
    id: number;
    product_concept: DrugbankProductConceptSimpleSerializer;
    title: string;
    rxcui: string;
    name: string;
    same_as: string;
    code: string;

    static viewSet = APIService.DrugbankProductConceptRxCuiViewSet;

    assign(x: any, ...args) {
        super.assign(x, ...args);

        this.name = this.title;
        this.same_as = this.product_concept.title;
        this.code = this.rxcui;
    }

    @MethodCache('convertObject')
    static list(filters: DrugbankProductConceptRxCuiViewSetQueryParams): Observable<DrugbankProductConceptRxCui[]> {
        return super.list(filters).pipe(shareReplay(1));
    }
}
